import { Injectable } from '@angular/core';
import { AccessRight } from '../interfaces/backend.response.interface';
import { Module } from '../enums/module';

@Injectable()
export class AccessRightService {
  public accessRights = {
    [Module.ACCOUNT_LIST]: undefined,
    [Module.ACCOUNT_GROUP]: undefined,
  };

  constructor() {}

  setAccessRight(module: Module, accessRight: AccessRight) {
    this.accessRights[module] = accessRight;
  }

  accessIsSet(module: Module): boolean {
    return this.accessRights[module] !== undefined;
  }

  hasReadAccess(module: Module): boolean {
    return (
      this.accessRights[module] === AccessRight.READ ||
      this.accessRights[module] === AccessRight.WRITE
    );
  }

  hasWriteAccess(module: Module): boolean {
    return (
      this.accessRights[module] !== undefined && this.accessRights[module] === AccessRight.WRITE
    );
  }

  isWriteAccess(accessName: string): boolean {
    return accessName === AccessRight.WRITE;
  }

  isReadAccess(accessName: string): boolean {
    return accessName === AccessRight.READ;
  }

  isNoAccess(accessName: string): boolean {
    return accessName === AccessRight.NONE;
  }
}
