import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BackendResponseInterface } from '../interfaces/backend.response.interface';
import { environment } from '../../../environments/environment';
import { Routes } from '../routes/routes';
import {
  CountUnassignedAmountInterface,
  MathOperationInterface,
} from '../interfaces/math-operation.interface';
import { NetGrossResponseInterface } from '../interfaces/net-gross-response.interface';

@Injectable()
export class MathOperationsNetwork {
  constructor(private http: HttpClient) {}

  public multiply(
    leftOperand: string,
    rightOperand: string,
  ): Observable<BackendResponseInterface<MathOperationInterface>> {
    const params = new HttpParams({
      fromObject: {
        leftOperand,
        rightOperand,
      },
    });

    return this.http.get<BackendResponseInterface<MathOperationInterface>>(
      environment.url + Routes.MATH_MULTIPLY,
      { params },
    );
  }

  public sum(elements: string[]): Observable<BackendResponseInterface<MathOperationInterface>> {
    const params = new HttpParams({
      fromObject: {
        collection: elements.join('|'),
      },
    });

    return this.http.get<BackendResponseInterface<MathOperationInterface>>(
      environment.url + Routes.MATH_SUM,
      { params },
    );
  }

  public countUnassignedAmount(
    isNet: boolean,
    tax: number,
    left: string,
    right,
  ): Observable<BackendResponseInterface<CountUnassignedAmountInterface>> {
    const params = {
      leftOperand: left,
      rightOperand: right,
      isNet,
    };

    return this.http.post<BackendResponseInterface<CountUnassignedAmountInterface>>(
      environment.url + Routes.MATH_COUNT_UNASSIGNED_AMOUNT,
      params,
    );
  }

  public calculateNetGrossValues(
    value: string,
    isNet: boolean,
    taxId: number,
  ): Observable<BackendResponseInterface<NetGrossResponseInterface>> {
    const params = new HttpParams({
      fromObject: {
        isNet: isNet.toString(),
        value,
        tax: taxId.toString(),
      },
    });

    return this.http.get<BackendResponseInterface<NetGrossResponseInterface>>(
      environment.url + Routes.MATH_CALCULATE_NET_GROSS,
      { params },
    );
  }
}
