import { Injectable } from '@angular/core';
import { CPOptionInterface, ProjectOptionInterface } from '../interfaces/project-list.interface';
import { BookingFormNetwork } from '../networks/booking-form.network';
import { LanguagesService } from '../../../../core/services/languages.service';
import { LanguageTranslationsBackend } from '../../../../core/interfaces/languageTranslations.backend';
import { BackendResponseDropdownDataInterface } from '../../../../core/interfaces/backend.response.interface';

@Injectable()
export class AddProjectByKnumberService {
  search = '';

  page = 1;

  pageSize = 10;

  cPs: CPOptionInterface[] = [];

  isLoading = false;

  selectedCP: CPOptionInterface;

  canLoadMore = true;

  client: number;

  projectReactivation = false;

  errorMessage = '';

  constructor(public network: BookingFormNetwork, public languagesService: LanguagesService) {}

  loadCPs(concat = false) {
    if (!concat) {
      this.cPs = [];
    }
    this.isLoading = true;
    this.errorMessage = '';

    this.network
      .fetchCpList(this.search, this.client, this.page, this.pageSize)
      .subscribe(
        ({ errorMessage, items }: BackendResponseDropdownDataInterface<CPOptionInterface[]>) => {
          this.isLoading = false;
          this.errorMessage = errorMessage;
          this.cPs = concat ? [...this.cPs, ...items] : items;
          this.canLoadMore = this.page === 1 || items.length === this.pageSize;
        },
      );
  }

  initialLoadCPs() {
    this.page = 1;
    this.loadCPs();
  }

  incrementPageForCPs() {
    if (!this.canLoadMore || this.isLoading) {
      return;
    }
    this.page++;
    this.loadCPs(true);
  }

  clearValue() {
    this.selectedCP = undefined;
    this.search = '';
    this.cPs = [];
    this.canLoadMore = true;
  }

  getTransValue(value: LanguageTranslationsBackend) {
    return this.languagesService.getLanguageValue(value);
  }
}
