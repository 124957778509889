import { LanguageTranslationsBackend } from '../../../../../core/interfaces/languageTranslations.backend';
import { Money } from '../../../../../shared/troi-money/money';
import { Currency } from '../../../../../shared/troi-money/currency';
import { PersonInterface } from '../../../../../shared/troi-person/interfaces/person.interface';
import { BudgetStatusInterface } from '../../../interfaces/budget-status.interface';
import { OrderingStatusEnum } from '../../../enum/ordering-status.enum';
import { OrderingStatusCountsInterface } from '../../../interfaces/ordering-status-counts.interface';

export class ProjectPrice {
  public constructor(public totalCalculation: Money, public totalSumClosedInvoices: Money) {}
}

export class ProjectPrices {
  public constructor(public currency: Currency, public price: ProjectPrice) {}
}

export class ProjectPropertiesModel {
  constructor(
    public name: LanguageTranslationsBackend,
    public inFavorites: boolean,
    public projectNumber: string,
    public customer: LanguageTranslationsBackend,
    public customerContact: string,
    public projectLeader: PersonInterface,
    public projectType: string[],
    public projectStatus: number,
    public reportingDate: number,
    public orderingDate: number,
    public orderingStatusCounts: OrderingStatusCountsInterface,
    public orderingStatus: OrderingStatusEnum,
    public approvalStatus: boolean,
    public approvalDate: number,
    public projectStartEndDate: number[],
    public prices: ProjectPrices,
    public active: boolean,
    public hasProjectPlan: boolean,
    public actualToDebitAmount: BudgetStatusInterface,
    public actualToDebitQuantity: BudgetStatusInterface,
    public actualToDebitInternalAmount: BudgetStatusInterface,
    public actualToDebitInternalQuantity: BudgetStatusInterface,
  ) {}
}
