export enum StorageKeys {
  ACTIVE_CURRENCY = 'activeCurrency',
  COUNTRIES = 'countries',
  PRICE_LIST_SETTINGS = 'priceListSettings',
  ACCOUNTING_SETTINGS = 'accountingSettings',
  BOOKINGS_SETTINGS = 'bookingsSettings',
  PROJECT_LIST_SETTINGS = 'projectListSettings',
  PROJECT_BUDGET_THRESHOLDS = 'projectBudgetThresholds',
  DESK_SETTINGS = 'deskSettings',
  USER = 'user',
  REMEMBER_ME = 'rememberMe',
  RETURN_URL = 'returnUrl',
  FILTERS = 'filters',
  DATA_LANG = 'dataLang',
  LANG = 'lang',
  THEME_COLOR = 'themeColor',
  NOTIFICATION = 'notification',
}
