import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ModuleInterceptor } from '../../../core/enums/module-interceptor';
import { Observable, of, Subscriber } from 'rxjs';
import { mapValues } from 'lodash';
import {
  BackendResponseInterface,
  BackendResponseType,
  BackendResponseWithSettingsInterface,
} from '../../../core/interfaces/backend.response.interface';
import { DropdownDataSelectedInterface } from '../interfaces/dropdown-data-selected.interface';
import { IncomingInvoiceInterface } from '../interfaces/incoming-invoice.interface';
import { map } from 'rxjs/operators';
import { IncomingInvoiceFactory } from '../factories/incoming-invoice.factory';
import { IncomingInvoiceModel } from '../models/incoming-invoice.model';
import { InvoiceAssignNumberInterface } from '../interfaces/invoice-assign-number.interface';
import { InvoiceAssignNumberModel } from '../models/invoice-assign-number.model';
import { InvoiceAssignNumberFactory } from '../factories/invoice-assign-number.factory';
import { delay } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Routes } from '../enum/routes';
import { FormGroup } from '@angular/forms';
import { ExportListInterface } from '../interfaces/export-list.interface';
import { ExportStatusEnum } from '../enum/export-status.enum';
import { sample } from 'lodash';
import { FiltersParamsInterface } from '../../common/interfaces/filters-params.interface';
import { LanguagesService } from '../../../core/services/languages.service';
import { FiltersBaseInterface } from '../../../core/interfaces/filtersBase.interface';
import { EditBilling } from '../../billings/modals/editBilling/models/edit-billing.model';
import { SettingsInterface } from '../../billings/modals/editBilling/interfaces/settings.interface';
import { EditBillingItemInterface } from '../../billings/modals/editBilling/interfaces/edit-billing-item-interface';

@Injectable()
export class ProjectListNetwork {
  public headers: HttpHeaders;

  constructor(
    private httpClient: HttpClient,
    private invoiceAssignNumberFactory: InvoiceAssignNumberFactory,
    private incomingInvoiceFactory: IncomingInvoiceFactory,
    private languageService: LanguagesService,
  ) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.PROJECT_LIST, '1');
  }

  public getDropdownSelectedData(
    projectType: string,
    projectManager: string,
  ): Observable<DropdownDataSelectedInterface> {
    const params = new HttpParams({
      fromObject: {
        projectType,
        projectManager,
      },
    });

    return this.httpClient.get<DropdownDataSelectedInterface>(
      environment.url + Routes.DROPDOWN_DATA_SELECTED,
      { params, headers: this.headers },
    );
  }

  public createExport(
    exportForm: FormGroup,
    filtersParams: FiltersParamsInterface,
  ): Observable<BackendResponseInterface<ExportListInterface>> {
    return this.httpClient.post<BackendResponseInterface<ExportListInterface>>(
      environment.url + Routes.EXPORT,
      {
        ...exportForm.value,
        ...filtersParams,
        lang: this.languageService.getDataLanguage(),
      },
      { headers: this.headers },
    );
  }

  // TODO: TROR-19666 remove progress param when API is implemented
  public getExport(
    exportId: string | number,
    progress = 0,
  ): Observable<BackendResponseInterface<ExportListInterface>> {
    const responseTimes = [50, 100, 250, 500, 1000, 2000];
    const randomResponseTime = sample(responseTimes);

    return this.httpClient.get<BackendResponseInterface<ExportListInterface>>(
      `${environment.url}${Routes.EXPORT}/${exportId}`,
      { headers: this.headers },
    );
  }

  fetchInvoiceAssignNumbers(
    projectId: string | number,
    paramsList: {
      [key: string]: string | number | boolean;
    },
    showAll = false,
  ): Observable<BackendResponseInterface<InvoiceAssignNumberModel[]>> {
    const params = new HttpParams({
      fromObject: {
        ...mapValues(paramsList, (value: string | number | boolean) => String(value)),
      },
    });

    return this.httpClient
      .get<BackendResponseInterface<InvoiceAssignNumberModel[]>>(
        `${environment.url}${Routes.INVOICE_ASSIGNED_NUMBER_LIST}/${projectId}`,
        { params, headers: this.headers },
      )
      .pipe(
        map((response: BackendResponseInterface<InvoiceAssignNumberInterface[]>) => {
          return {
            ...response,
            data: this.invoiceAssignNumberFactory.buildInvoiceAssignNumbers(response.data, showAll),
          };
        }),
      );
  }

  // public fetchIncomingInvoices(
  //   projectId: string | number,
  //   paramsList: {
  //     [key: string]: string | number;
  //   },
  // ): Observable<BackendResponseInterface<IncomingInvoiceModel>> {
  //   const params = new HttpParams({
  //     fromObject: {
  //       ...mapValues(paramsList, (value: string | number) => String(value)),
  //     },
  //   });
  //
  //   return this.httpClient.get<BackendResponseInterface<IncomingInvoiceInterface>>(
  //     `${environment.url}${Routes.INCOMING_INVOICE_LIST}/${projectId}`,
  //     { params, headers: this.headers },
  //   );
  // }

  public fetchIncomingInvoices(
    projectId: string | number,
    paramsList: {
      [key: string]: string | number | boolean;
    },
  ): Observable<BackendResponseInterface<IncomingInvoiceModel>> {
    const params = new HttpParams({
      fromObject: {
        ...mapValues(paramsList, (value: string | number) => String(value)),
      },
    });
    // @ts-ignore
    return this.httpClient
      .get<BackendResponseWithSettingsInterface<EditBillingItemInterface[], SettingsInterface>>(
        `${environment.url}${Routes.INCOMING_INVOICE_LIST}/${projectId}`,
        { params, headers: this.headers },
      )
      .pipe(
        map(
          (
            response: BackendResponseWithSettingsInterface<
              EditBillingItemInterface[],
              SettingsInterface
            >,
          ) => {
            // @ts-ignore
            return {
              ...response.data,
              // @ts-ignore
              data: this.incomingInvoiceFactory.buildIncomingInvoices(response.data.data),
            };
          },
        ),
      );
  }

  public deleteExport(
    exportId: string | number,
  ): Observable<BackendResponseInterface<ExportListInterface>> {
    return this.httpClient.delete<BackendResponseInterface<ExportListInterface>>(
      `${environment.url}${Routes.EXPORT}/${exportId}`,
      { headers: this.headers },
    );
  }

  public getCustomers(
    selectedClientId: number,
  ): Observable<BackendResponseInterface<FiltersBaseInterface[]>> {
    const params = new HttpParams({
      fromObject: {
        client: selectedClientId.toString(),
      },
    });

    return this.httpClient.get<BackendResponseInterface<FiltersBaseInterface[]>>(
      `${environment.url}${Routes.FILTERS_CUSTOMER}`,
      { params, headers: this.headers },
    );
  }
}
