import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../../troi-modals/modal.service';
import { FooterSettingsService } from '../../../../core/services/footer-settings.service';
import { BaseModalDirective } from '../../../troi-base-modal/baseModal.component';

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss'],
})
export class SettingsModalComponent extends BaseModalDirective implements OnInit {
  public submitted = false;

  public modalObject: any;

  public form: FormGroup;

  constructor(public modalService: ModalService, private settingsService: FooterSettingsService) {
    super(modalService);
    this.modalObject = this.modalService.object;
  }

  ngOnInit() {
    this.initForm();
  }

  public save() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.settingsService.setPerPage(this.form.get('itemsPerPage').value);
    this.close();
  }

  public initForm() {
    this.form = new FormGroup({
      itemsPerPage: new FormControl(this.modalObject.perPage, Validators.required),
    });
  }

  isNumberValid(): boolean {
    return this.form.value.itemsPerPage > 0 && this.form.value.itemsPerPage <= 1000;
  }
}
