import { FilterElementInterface } from '../troi-filter-with-modal/filter-element.interface';
import { Currency } from '../troi-money/currency';

export class FiltersInterface {
  search: string;

  dropdownFirst: number;

  dropdownSecond: number | string | number[] | string[];

  dropdownThird?: number | string | number[] | string[];

  filters: FilterElementInterface[];

  currentPage: number;

  pageSize: number;

  sortingDir?: string;

  sortBy?: string;

  dropdownOptional?: FilterElementInterface;
}

export class CurrencyMoneyInterface {
  currency: Currency;

  decimalPlaces: number;
}
