<div class="troi-budget-status" [ngClass]="getWarnClass(budgetStatus.percentUsed)">
  <div class="troi-budget-status__values">
    <div
      class="values__percent"
      (mouseover)="showSpentBudgetInUnit = true"
      (mouseout)="valuesMouseOut($event)"
    >
      <span [hidden]="showSpentBudgetInUnit"
        >{{ budgetStatus.percentUsed || 0 | troiNumber: numberConfig }}%</span
      >
      <span [hidden]="!showSpentBudgetInUnit"
        >{{ budgetStatus.usedUnits | troiNumber: numberConfig }} {{ unitOfMeasure }}</span
      >
    </div>
    <div
      class="values__units"
      [ngbTooltip]="budgetStatusTooltip"
      (mouseover)="showBudgetStatusTooltip = true"
      (mouseout)="unitsMouseOut($event)"
      placement="bottom-right"
      tooltipClass="troi-budget-status__tooltip"
    >
      <span [hidden]="!showBudgetStatusTooltip" class="units__total"
        >{{ 'Common.labels.total' | translate }}:
      </span>
      <span>{{ budgetStatus.plannedUnits | troiNumber: numberConfig }} {{ unitOfMeasure }}</span>
    </div>
  </div>
  <div
    class="troi-budget-status__progress progress"
    [ngClass]="getWarnClass(budgetStatus.percentUsed)"
    [ngbTooltip]="showBudgetStatusTooltip ? budgetStatusTooltip : null"
    placement="bottom-right"
    tooltipClass="troi-budget-status__tooltip"
  >
    <div
      class="progress__bar"
      role="progressbar"
      (mouseover)="showBudgetStatusTooltip = false; showSpentBudgetInUnit = true"
      (mouseout)="progressBarMouseOut($event)"
      [ngStyle]="{ width: filledBarWidth }"
      [attr.aria-valuenow]="budgetStatus.percentUsed"
      [attr.aria-valuemin]="0"
      [attr.aria-valuemax]="100"
    ></div>
    <div
      class="progress__empty-bar"
      role="progressbar"
      (mouseover)="showBudgetStatusTooltip = true; showSpentBudgetInUnit = false"
      (mouseout)="progressEmptyBarMouseOut($event)"
      [ngStyle]="{ width: remainingBarWidth }"
      [attr.aria-valuenow]="budgetStatus.percentUsed"
      [attr.aria-valuemin]="0"
      [attr.aria-valuemax]="100"
    ></div>
  </div>
</div>

<ng-template #budgetStatusTooltip>
  <span
    >{{ 'Common.labels.stillAvailable' | translate }} :<br />
    {{ budgetStatus.plannedUnits - budgetStatus.usedUnits | troiNumber: numberConfig }}
    {{ unitOfMeasure }}</span
  >
</ng-template>
