<div class="filters-section" [ngClass]="{ 'filters-section--with-header': header }">
  <ng-container *ngIf="header; else filtersRow">
    <div class="filters-section__header-row">
      <div class="filters-section__header">
        <span class="filters-section__title">{{ header | translate }}</span>
        <troi-filter-sets
          *ngIf="filterSets?.length > 0"
          class="filters-section__filter-sets"
          [selectedFilterSetId]="selectedFilterSetId"
          [filterSets]="filterSets"
          [areFiltersEdited]="areFiltersEdited"
          (filterSetSelected)="emitFilterSetSelected($event)"
          (filterSetDeleted)="emitFilterSetDeleted($event)"
          (filterSetAsDefault)="emitFilterSetAsDefault($event)"
          data-test="projects-visibility-dropdown"
        ></troi-filter-sets>
      </div>
      <div class="filters-section__quick-filters">
        <ng-container *ngTemplateOutlet="quickFilters"></ng-container>
      </div>
    </div>

    <div class="filters-section__main-filters-row">
      <ng-container *ngTemplateOutlet="mainFilters"></ng-container>
      <div class="filters-sections__options">
        <ng-content></ng-content>
      </div>
    </div>
  </ng-container>

  <ng-template #filtersRow>
    <ng-container *ngTemplateOutlet="mainFilters"></ng-container>
    <ng-container *ngTemplateOutlet="quickFilters"></ng-container>
  </ng-template>
</div>

<ng-template #mainFilters>
  <div
    class="filters-section__search filters-section__item"
    [ngClass]="{ 'filters-section__search--active': searchActive() }"
  >
    <troi-filter class="filters__filter filters__filter--search">
      <troi-icon icon="icon-loop-with-arrow-down"></troi-icon>
      <input
        type="text"
        name="search"
        (keyup)="searchInput.next($event)"
        [(ngModel)]="filters.search"
        placeholder="{{ 'Booking.labels.search' | translate }}"
      />
    </troi-filter>
  </div>
  <div class="filters-section__filters filters-section__item">
    <troi-filter-with-modal
      [filterData]="filters?.filters"
      [currencyMoneyData]="currencyMoneyData"
      (filtersApplied)="filtersApplied($event)"
      (filtersReset)="emitFiltersReset()"
      [year]="year"
      [client]="client"
      [lang]="lang"
      [limitDate]="limitDate"
      [dateFormat]="dateFormat"
    ></troi-filter-with-modal>
  </div>
  <div
    *ngIf="filterSets?.length > 0"
    class="filters-section__item filters-section__save-filter-set"
  >
    <div *ngIf="areFiltersEdited" (click)="filterSetOpenSave.emit()">
      <troi-icon icon="icon-save"></troi-icon>
      <span>{{ 'FilterSets.saveFilterSet' | translate }}</span>
    </div>
  </div>
  <div class="filters-section__filter-tabs filters-section__item" #filterChips>
    <troi-filter-chips
      [filters]="filters.filters"
      [reloadFilterChips]="reloadFilterChips"
      (filterClosed)="emitFiltersChanged()"
      [dateFormat]="dateFormat"
      [width]="filterChipsWidth"
    ></troi-filter-chips>
  </div>
</ng-template>

<ng-template #quickFilters>
  <div
    *ngIf="optionalDropdown && year && client && lang"
    class="filters-section__optional-dropdown filters-section__item"
  >
    <troi-dropdown-select-lazy
      [noBorder]="true"
      [filterObject]="client"
      [year]="year"
      [lang]="lang"
      [reloadInitData]="shouldReloadOptionalDropdownOptions()"
      [dataType]="optionalDropdown.dataType"
      [initValue]="optionalDropdown.value"
      [defaultEmptyValue]="optionalDropdown.defaultValue"
      (selected)="emitOptionalDropdownChanged($event)"
      (optionsLoaded)="updateOptionalDropdown($event)"
      [refreshPreloadedOptionsOnChange]="true"
      [predefinedOptions]="optionalDropdown?.preloadedOptions"
      [withSearch]="optionalDropdown.withSearch"
      [initLazyOptionOnInit]="optionalDropdown.loadOptionOnInit"
    ></troi-dropdown-select-lazy>
  </div>
  <div class="filters-section__dropdown filters-section__dropdown--first filters-section__item">
    <troi-dropdown-select
      [noBorder]="true"
      size="12px"
      row-value
      (selected)="emitFirstDropdownChanged()"
      [(ngModel)]="filters.dropdownFirst"
      name="dropdownFirst"
      data-test="client-dropdown"
      [options]="firstDropdown"
    >
    </troi-dropdown-select>
  </div>
  <div class="filters-section__dropdown filters-section__dropdown--second filters-section__item">
    <troi-dropdown-select
      [noBorder]="true"
      [search]="secondDropdownWithConfig.search"
      [multipleSelect]="secondDropdownWithConfig.multiple"
      [disable]="secondDropdownWithConfig.disable"
      [selectAllOption]="secondDropdownWithConfig.selectAllOption"
      [selectAllOptionLabel]="secondDropdownWithConfig?.selectAllOptionLabel"
      size="12px"
      row-value
      (selected)="emitSecondDropdownChanged()"
      [initValue]="filters.dropdownSecond"
      [(ngModel)]="filters.dropdownSecond"
      name="dropdownSecond"
      data-test="customer-dropdown"
      [options]="secondDropdownWithConfig.values"
      [reloadPreloadedOptions]="reloadSecondDropdownOptions"
      [resetMultipleSelect]="resetSecondDropdown"
      [placeholder]="secondDropdownWithConfig?.placeholder | translate"
    >
    </troi-dropdown-select>
  </div>
  <div
    *ngIf="thirdDropdownWithConfig"
    class="filters-section__dropdown filters-section__dropdown--third filters-section__item"
  >
    <troi-dropdown-select
      [noBorder]="true"
      [search]="thirdDropdownWithConfig.search"
      [multipleSelect]="thirdDropdownWithConfig.multiple"
      [disable]="thirdDropdownWithConfig.disable"
      [selectAllOption]="thirdDropdownWithConfig.selectAllOption"
      [selectAllOptionLabel]="thirdDropdownWithConfig?.selectAllOptionLabel"
      size="12px"
      row-value
      (selected)="emitThirdDropdownChanged()"
      [initValue]="filters.dropdownThird"
      [(ngModel)]="filters.dropdownThird"
      name="dropdownThird"
      data-test="project-status-dropdown"
      [options]="thirdDropdownWithConfig.values"
      [reloadPreloadedOptions]="reloadThirdDropdownOptions"
      [resetMultipleSelect]="resetThirdDropdown"
      [placeholder]="thirdDropdownWithConfig?.placeholder | translate"
    >
    </troi-dropdown-select>
  </div>
  <div class="filters-section__columns filters-section__item">
    <troi-columns-selector
      [disabled]="columnDisabled()"
      [columnList]="columnList"
      (columnsSaved)="onColumnChanged($event)"
    ></troi-columns-selector>
  </div>
</ng-template>
