import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { StorageNotificationService } from '../notifications/storageNotification.service';
import { BackendErrorTranslationService } from '../services/backendError.translation.service';
import { BackendErrorInterface, BackendErrorType } from '../interfaces/backend.error.interface';

export enum ErrorStatus {
  Success = 200,
  Unauthorized = 401,
  NoAccessToAction = 403,
  NOT_FOUND = 404,
  ERROR = 500,
  USER_ERROR = 400,
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  public static prepareErrorsFromDetails(errors): BackendErrorInterface[] {
    return errors.map((error) => ({
      field: BackendErrorType.FORM,
      messages: [error.message],
    }));
  }

  constructor(
    private router: Router,
    private notificationService: StorageNotificationService,
    private errorService: BackendErrorTranslationService,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.overrideRequest(req, next);
  }

  private overrideRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === ErrorStatus.Unauthorized) {
          this.router.navigate(['/login']);
        }
        if (err.status === ErrorStatus.NoAccessToAction) {
          this.errorService.showAccessToActionDeniedError();
        }
        if (
          err.status === ErrorStatus.USER_ERROR ||
          err.status === ErrorStatus.NOT_FOUND ||
          err.status === ErrorStatus.ERROR ||
          err.status === 0
        ) {
          try {
            if (err.error.data !== undefined) {
              this.errorService.showError(err.error.data);
            } else {
              const errors = this.getErrorMessages(err);

              if (
                !(
                  errors.includes('ProjectList.error.backend.projectDeleteNotEmpty') ||
                  errors.includes('ProjectList.error.backend.hasBilling')
                ) ||
                errors.length !== 1
              ) {
                this.errorService.showError(
                  HttpErrorInterceptor.prepareErrorsFromDetails(err.error.details),
                );
              }
            }
          } catch (e) {
            this.errorService.getGeneralError().subscribe((error) => {
              if (
                err.error?.details?.includes('tree_folder_already_exist') ||
                err.error?.details?.includes('project_number_manual_already_exists')
              ) {
                return;
              }
              this.notificationService.showError(error);
            });
          }
        }

        return observableThrowError(err);
      }),
    );
  }

  private getErrorMessages(err: HttpErrorResponse) {
    const errors = err.error.details.reduce((acc, err) => {
      acc.push(err.message);
      return acc;
    }, []);
    return errors;
  }
}

export const HttpErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorInterceptor,
  multi: true,
};
