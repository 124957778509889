import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { DropdownsNetwork } from '../networks/dropdowns.network';
import { BackendResponseDropdownDataInterface } from '../../../core/interfaces/backend.response.interface';
import { DropdownRoutesEnum } from '../enums/dropdown-routes.enum';
import { TroiDropdownListModel } from '../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { DataTypeEnum } from '../../../core/enums/data-type.enum';
import { LanguagesService } from '../../../core/services/languages.service';
import { AccountBookingContextInterface } from '../../bookings/common/interfaces/account-booking-context.interface';
import { HttpHeaders } from '@angular/common/http';
import { TranslationMode } from '../../../core/enums/translationMode';
import { FiltersParamsInterface } from '../interfaces/filters-params.interface';
import { Routes } from '../../project-list/enum/routes';

@Injectable()
export class DropdownsService {
  constructor(private network: DropdownsNetwork, private languagesService: LanguagesService) {}

  public fetchObjects(
    filterObject: number,
    page: number,
    pageSize: number,
    search: string,
    lang: string,
    route: DropdownRoutesEnum | string,
    searchFieldKey = 'name',
    filterObjectKey = 'client',
    headers?: HttpHeaders,
  ): Observable<BackendResponseDropdownDataInterface<any>> {
    // TODO: refactor, move to project folder scope
    if (route === Routes.DROPDOWN_PROJECT_FOLDER) {
      return this.network.fetchProjectFolders(
        route,
        filterObject,
        filterObjectKey,
        page,
        pageSize,
        search,
        searchFieldKey,
        lang,
        null,
        headers,
      );
    }

    return this.network.fetchDropdownData(
      route,
      filterObject,
      filterObjectKey,
      page,
      pageSize,
      search,
      searchFieldKey,
      lang,
      null,
      headers,
    );
  }

  public fetchProtocolStatus(
    client: number,
    page: number,
    pageSize: number,
    search: string,
    lang: string,
    year: number,
  ): Observable<BackendResponseDropdownDataInterface<any>> {
    return this.network.fetchDropdownData(
      DropdownRoutesEnum.PROTOCOL_STATUSES,
      client,
      'client',
      page,
      pageSize,
      search,
      'name',
      lang,
      year,
    );
  }

  fetchCPs(
    subproject: number,
    client: number,
    page: number,
    pageSize: number,
    search: string,
    lang: string,
  ): Observable<BackendResponseDropdownDataInterface<any>> {
    return this.network.fetchCPs(client, subproject, page, pageSize, search, lang);
  }

  fetchAccountsForCreateBooking(
    client: number,
    supplier: string,
    context: string,
    page: number,
    pageSize: number,
    name: string,
    lang: string,
  ): Observable<BackendResponseDropdownDataInterface<AccountBookingContextInterface[]>> {
    return this.network.fetchAccountsForCreateBooking(
      client,
      supplier,
      context,
      page,
      pageSize,
      name,
      lang,
    );
  }

  fetchCPsByKNumber(
    page: number,
    pageSize: number,
    search: string,
    lang: string,
    route: string,
    params?: FiltersParamsInterface,
    headers?: HttpHeaders,
  ): Observable<BackendResponseDropdownDataInterface<any>> {
    return this.network.fetchCPsByKNumber(page, pageSize, search, lang, route, params, headers);
  }

  buildOptionList(
    data,
    dataType: DataTypeEnum,
    translatableName = false,
    preloadedOptions: Array<TroiDropdownListModel> = [],
    colorizeBackgroundProperty = '',
    translationMode = TranslationMode.DATA,
    multipleChoiceGroup = false,
    maxSelectionsGroup = 0,
  ): Array<TroiDropdownListModel> {
    const preloadedValues = preloadedOptions.map((option) => option.value);
    const list = [];
    const key =
      dataType === DataTypeEnum.COST_CENTERS || dataType === DataTypeEnum.ACCOUNT_GROUPS
        ? 'description'
        : 'name';

    _.forEach(data, (element) => {
      if (preloadedValues.includes(element.id)) {
        return;
      }
      let listElement = {
        label: translatableName
          ? this.languagesService.getLanguageValue(element[key], translationMode)
          : element.number
          ? `${element.number} - ${element[key]}`
          : element[key],
        value: element.id,
        active: true,
        hasCreditorAccount: element.hasCreditorAccount,
        fullObject: element,
        colorizeBackground: false,
        multipleChoice: element.multipleChoice || false,
        multipleChoiceGroup,
        maxSelections: element.maxSelections || 0,
        maxSelectionsGroup,
        isRequired: element.isRequired || false,
        group: element.children && element.children.length > 0,
        groupValues: element.children
          ? this.buildOptionList(
              element.children,
              dataType,
              translatableName,
              [],
              colorizeBackgroundProperty,
              translationMode,
              element.multipleChoice || false,
              element.maxSelections || 0,
            )
          : [],
        parent: element.parent || null,
      };

      if (colorizeBackgroundProperty !== '') {
        listElement = { ...listElement, colorizeBackground: element[colorizeBackgroundProperty] };
      }

      list.push(listElement);
    });

    return [...preloadedOptions, ...list];
  }
}
