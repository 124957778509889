import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { FiltersInterface } from '../../../shared/troi-data-listing-filters/filters.interface';
import { TroiDropdownListModel } from '../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { FilterElementInterface } from '../../../shared/troi-filter-with-modal/filter-element.interface';
import { FilterTypeEnum } from '../../../shared/troi-filter-with-modal/filter-type.enum';
import { Money } from '../../../shared/troi-money/money';
import { ParentWindowRef } from '../../../core/services/parent-window-ref.service';
import { FooterSettingsService } from '../../../core/services/footer-settings.service';
import { BasicFiltersService } from '../../../core/services/basic-filters.service';
import { Currency } from '../../../shared/troi-money/currency';
import { StorageService } from '../../../core/storage/storage.service';
import { cloneDeep, findIndex } from 'lodash';
import { TroiFilterSetsService } from '../../../shared/troi-filter-sets/services/troi-filter-sets.service';
import { TroiFilterSetsNetworkService } from '../../../shared/troi-filter-sets/network/troi-filter-sets.network';
import { FilterElementDefaultStateInterface } from '../../../shared/troi-filter-with-modal/filter-element-default-state.interface';
import { FilterSetModel } from '../../../shared/troi-filter-sets/models/filter-set.model';

export class CommonFiltersService extends TroiFilterSetsService {
  public actualFilters: FiltersInterface;
  public defaultClientAssigned = new Subject();
  public pageUrl;

  constructor(
    public basicFiltersService: BasicFiltersService,
    public parentWindowRef: ParentWindowRef,
    public footerSettingsService: FooterSettingsService,
    protected localStorage: StorageService,
    protected filterSetsNetworkService: TroiFilterSetsNetworkService,
    protected withoutCustomers: boolean = true,
  ) {
    super(filterSetsNetworkService);
    this.basicFiltersService.fetchClients(withoutCustomers);
    this.pageUrl = this.parentWindowRef.nativeWindow.location;
  }

  public static isFilterActive = (filter: FilterElementInterface) =>
    typeof filter.disabled !== 'boolean' && typeof filter.disabled !== 'undefined'
      ? !filter.disabled()
      : !filter.disabled;

  public static isFilterExplicit = (filter: FilterElementInterface) => filter.explicit;

  resetPagination() {
    this.actualFilters.currentPage = 1;
    this.actualFilters.pageSize = this.getPageSize();
  }

  getDefaultClientId(): number {
    return this.basicFiltersService.defaultClientId();
  }

  getSelectedClientId(): number {
    return this.actualFilters.dropdownFirst;
  }

  generateFirstDropdown(): Array<TroiDropdownListModel> {
    return this.basicFiltersService.generateClientsDropdown();
  }

  decrementPage() {
    if (this.actualFilters.currentPage > 1) {
      this.actualFilters.currentPage--;
    }
  }

  getPageSize(): number {
    return this.footerSettingsService.readFromLS().perPage;
  }

  getCurrentPage(): number {
    return this.actualFilters.currentPage;
  }

  syncActualPageSize() {
    this.actualFilters.pageSize = this.getPageSize();
  }

  generateUrlFromParams(params, tempUrl: URL, isServerSide: boolean = true): string {
    const url = isServerSide
      ? `index.php?page=${tempUrl.searchParams.get('page')}`
      : `${tempUrl.pathname}?`;

    return `${url}${this.generateUrlParams(params)}`;
  }

  public generateUrlParams(params: Record<string, any>, withEmptyValues = false): string {
    let url = '';
    _.forOwn(params, (value, key) => {
      if (value !== '' || withEmptyValues) {
        url += `&${key}=${value}`;
      }
    });

    return url;
  }

  applyCommonUrlFilters(
    tempUrl: URL,
    filters: FiltersInterface,
    withActivity = true,
    allowAssignEmptyInput = false,
  ): FiltersInterface {
    if (tempUrl.searchParams.get('client')) {
      filters.dropdownFirst = parseInt(tempUrl.searchParams.get('client'), 10);
    }

    if (allowAssignEmptyInput || tempUrl.searchParams.get('searchPhrase')) {
      filters.search = tempUrl.searchParams.get('searchPhrase');
    }

    if (tempUrl.searchParams.get('currentPage')) {
      filters.currentPage = parseInt(tempUrl.searchParams.get('currentPage'), 10);
    }
    if (tempUrl.searchParams.get('pageSize')) {
      filters.pageSize = parseInt(tempUrl.searchParams.get('pageSize'), 10);
    }

    if (tempUrl.searchParams.get('sortBy')) {
      filters.sortBy = tempUrl.searchParams.get('sortBy');
    }
    if (tempUrl.searchParams.get('sortDir')) {
      filters.sortingDir = tempUrl.searchParams.get('sortDir');
    }

    if (withActivity) {
      const showActiveIndex = _.findIndex(this.actualFilters.filters, { formName: 'showActive' });
      const showInactiveIndex = _.findIndex(this.actualFilters.filters, {
        formName: 'showInactive',
      });

      if (tempUrl.searchParams.get('showActive') && showActiveIndex !== -1) {
        this.actualFilters.filters[showActiveIndex].value =
          tempUrl.searchParams.get('showActive') === 'true';
      }
      if (tempUrl.searchParams.get('showInactive') && showInactiveIndex !== -1) {
        this.actualFilters.filters[showInactiveIndex].value =
          tempUrl.searchParams.get('showInactive') === 'true';
      }
    }

    return filters;
  }

  protected setFilterFromUrl(url: URL, filterParam: string, group = false): void {
    if (url.searchParams.has(filterParam)) {
      const itemIndex = this.getFilterParamIndex(group ? 'group' : filterParam);

      if (group) {
        const childIndex = this.getFilterParamIndex(
          filterParam,
          this.actualFilters.filters[itemIndex].value,
        );
        this.actualFilters.filters[itemIndex].value[childIndex].value = JSON.parse(
          url.searchParams.get(filterParam),
        );
      } else {
        this.actualFilters.filters[itemIndex].value = url.searchParams.get(filterParam);
      }
    }
  }

  protected setDateFilterFromUrl(tempUrl: URL, filterParam: string): void {
    const paramFrom = `${filterParam}From`;
    const paramTo = `${filterParam}To`;

    if (tempUrl.searchParams.has(paramFrom) || tempUrl.searchParams.has(paramTo)) {
      this.actualFilters.filters[this.getFilterParamIndex(filterParam)].value = [
        Number(tempUrl.searchParams.get(paramFrom)) || null,
        Number(tempUrl.searchParams.get(paramTo)) || null,
      ];
    }
  }

  activityFilters(): FilterElementInterface[] {
    return [
      {
        type: FilterTypeEnum.SWITCH,
        label: 'Booking.labels.showActive',
        formName: 'showActive',
        value: true,
        defaultValue: true,
        chips: [
          {
            label: 'Booking.labels.active',
            value: 'Booking.labels.notShown',
            valueIsTranslatable: true,
          },
        ],
      },
      {
        type: FilterTypeEnum.SWITCH,
        label: 'Booking.labels.showInactive',
        formName: 'showInactive',
        value: false,
        defaultValue: false,
        chips: [
          {
            label: 'Booking.labels.inactive',
            value: 'Booking.labels.shown',
            valueIsTranslatable: true,
          },
        ],
      },
    ];
  }

  createMoney(value, systemCurrency: Currency, decimalPlacesNumber: number): Money {
    return new Money(value, systemCurrency, decimalPlacesNumber);
  }

  actualSorting() {
    return [
      {
        prop: this.actualFilters.sortBy,
        dir: this.actualFilters.sortingDir,
      },
    ];
  }

  putFiltersInUrl(params, isServerSide: boolean = true) {
    if (this.localStorage.getItem('behat')) {
      return;
    }

    this.parentWindowRef.nativeHistory.pushState(
      '',
      '',
      this.generateUrlFromParams(params, new URL(this.pageUrl.href), isServerSide),
    );
  }

  protected getFilterParamIndex(param: string, filters?: FilterElementInterface[]): number {
    return findIndex(filters || this.actualFilters.filters, { formName: param });
  }

  protected getDefaultFiltersState(
    filters: FilterElementInterface[],
  ): FilterElementDefaultStateInterface[] {
    return filters.reduce(
      (
        acc: FilterElementDefaultStateInterface[],
        filter: FilterElementInterface,
      ): FilterElementDefaultStateInterface[] => {
        const { formName, defaultValue } = filter;

        if (filter.type === FilterTypeEnum.GROUP) {
          return [...acc, ...this.getDefaultFiltersState(filter.value)];
        } else if (
          [FilterTypeEnum.RANGE, FilterTypeEnum.RANGE_DATE, FilterTypeEnum.RANGE_STRING].includes(
            filter.type,
          )
        ) {
          acc.push(
            ...[
              {
                formName: `${formName}From`,
                defaultValue: defaultValue[0] ? String(defaultValue[0]) : '',
              },
              {
                formName: `${formName}To`,
                defaultValue: defaultValue[1] ? String(defaultValue[1]) : '',
              },
            ],
          );
        } else {
          acc.push({
            formName,
            defaultValue: String(defaultValue),
          });
        }

        return acc;
      },
      [],
    );
  }

  protected assignActualFiltersToFilterSet(filterSetId: string | number) {
    this.filterSets = this.filterSets.map((s: FilterSetModel) => {
      if (s.id === filterSetId) {
        return {
          ...s,
          filters: cloneDeep(this.actualFilters),
        };
      } else {
        return s;
      }
    });
  }
}
