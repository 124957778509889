import { Injectable } from '@angular/core';
import { CommonFiltersFormService } from '../../common/services/common-filters-form.service';
import { FiltersInterface } from '../../../shared/troi-data-listing-filters/filters.interface';
import { FilterElementInterface } from '../../../shared/troi-filter-with-modal/filter-element.interface';
import { DataTypeEnum } from '../../../core/enums/data-type.enum';
import { TroiDropdownListModel } from '../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { FilterTypeEnum } from '../../../shared/troi-filter-with-modal/filter-type.enum';

@Injectable()
export class FiltersFormService extends CommonFiltersFormService {
  private static buildFilterWithMultipleAllSelectAndChildren(
    filterElem: FilterElementInterface,
  ): string {
    return Array.isArray(filterElem.value)
      ? filterElem.value.map((option: TroiDropdownListModel) => option.value).join(';')
      : filterElem.value;
  }

  private static buildFilterWithMultipleAllSelect(filterElem: FilterElementInterface): string {
    return Array.isArray(filterElem.value) ? filterElem.value.join(';') : filterElem.value;
  }

  prepareSearchRequestParams(filters: FiltersInterface) {
    const projectType = filters.filters.find(
      (filterElem: FilterElementInterface) => filterElem.dataType === DataTypeEnum.PROJECT_TYPES,
    );
    const projectManager = filters.filters.find(
      (filterElem: FilterElementInterface) => filterElem.dataType === DataTypeEnum.PROJECT_LEADERS,
    );

    const data = {
      customer: filters.dropdownSecond
        ? FiltersFormService.buildFilterWithMultipleAllSelect({
            value: filters.dropdownSecond,
            type: FilterTypeEnum.DROPDOWN,
            label: 'ProjectList.labels.filters.customer.placeholder',
          })
        : '',
      projectStatus: filters.dropdownThird
        ? FiltersFormService.buildFilterWithMultipleAllSelect({
            value: filters.dropdownThird,
            type: FilterTypeEnum.DROPDOWN,
            label: 'ProjectList.labels.filters.projectStatus.placeholder',
          })
        : '',
      projectType: projectType
        ? FiltersFormService.buildFilterWithMultipleAllSelectAndChildren(projectType)
        : '',
      projectManager: projectManager
        ? FiltersFormService.buildFilterWithMultipleAllSelect(projectManager)
        : '',
    };

    return {
      ...super.prepareSearchRequestParams(filters),
      ...data,
    };
  }
}
