import { Pipe, PipeTransform } from '@angular/core';
import { NumberPipe, TroiNumberConfigInterface } from './number.pipe';
import { sum } from 'lodash';

export interface SingularPluralConfigInterface {
  numberConfig: TroiNumberConfigInterface;
  translationSingular: string;
  translationPlural: string;
}

@Pipe({
  name: 'singularPlural',
})
export class SingularPluralPipe implements PipeTransform {
  constructor(private numberPipe: NumberPipe) {}

  transform(value: number | number[], config: SingularPluralConfigInterface): string {
    const formattedValue = this.numberPipe.transform(value, config.numberConfig);
    const sumValues = Array.isArray(value) ? sum(value) : value;
    return `${formattedValue} ${
      sumValues === 1 ? config.translationSingular : config.translationPlural
    }`;
  }
}
