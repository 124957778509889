import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { ApprovalsService } from '../../services/approvals.service';
import { ApprovalsNetwork } from '../../networks/approvals.network';

@Component({
  selector: 'approval-history',
  templateUrl: './approval-history.component.html',
  styleUrls: ['./approval-history.component.scss'],
})
export class ApprovalHistoryComponent implements AfterViewInit {
  noHistoryYet = 'no history yet';
  approvalHistory;
  showHistoryLimit = 2;
  showHistoryValue = 2;
  @Input() placement = 'bottom top';
  @Input() alignTop = false;
  @Input() id: string;

  @Output() openStateChanged = new EventEmitter<boolean>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public approvalsService: ApprovalsService,
    public approvalsNetwork: ApprovalsNetwork,
  ) {}

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  public async toggleOverlay(popover, event) {
    event.preventDefault();
    event.stopPropagation();
    if (popover.isOpen()) {
      this.closeOverlay(popover);
    } else {
      this.approvalHistory = await this.fetchData();
      this.openOverlay(popover);
    }
  }
  public showMoreHistory(limit: number) {
    this.showHistoryValue = limit;
  }
  private async fetchData() {
    return this.approvalsNetwork.history(this.id).toPromise();
  }

  openOverlay(popover): void {
    if (popover.isOpen() || this.approvalHistory?.state === this.noHistoryYet) {
      return;
    }
    popover.open();
    this.openStateChanged.emit(true);

    this.changeDetectorRef.detectChanges();
  }

  closeOverlay(popover): void {
    if (!popover.isOpen()) {
      return;
    }

    popover.close();
    this.openStateChanged.emit(false);
  }
}
