import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { TroiDropdownListModel } from '../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';

@Injectable()
export class PaymentMethodsService {
  public methods = [
    {
      id: 1,
      translationKey: 'Common.labels.cash',
    },
    {
      id: 2,
      translationKey: 'Common.labels.debitCard',
    },
    {
      id: 3,
      translationKey: 'Common.labels.creditCard',
    },
    {
      id: 4,
      translationKey: 'Common.labels.directDebit',
    },
    {
      id: 5,
      translationKey: 'Common.labels.transfer',
    },
  ];

  constructor(private translateService: TranslateService) {}

  buildPaymentMethods(): Array<TroiDropdownListModel> {
    const data: Array<TroiDropdownListModel> = [];

    _.forEach(this.methods, (single) => {
      data.push({
        label: this.translateService.instant(single.translationKey),
        value: single.id,
        active: true,
      });
    });

    data.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    data.unshift({
      label: this.translateService.instant('Booking.labels.pleaseSelect'),
      value: '',
      active: true,
    });

    return data;
  }
}
