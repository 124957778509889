import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { SaveFilterSetFormService } from './services/save-filter-set-form.service';
import { BaseModalDirective } from '../../../troi-base-modal/baseModal.component';
import { ModalService } from '../../../troi-modals/modal.service';
import { LanguagesService } from '../../../../core/services/languages.service';
import { FilterSetModel } from '../../models/filter-set.model';
import { BackendResponseInterface } from '../../../../core/interfaces/backend.response.interface';
import { FilterSetSaveStepEnum } from '../../enums/filter-set-save-state.enum';
import { FilterSetTypeEnum } from '../../enums/filter-set-type.enum';
import { FilterSetSavedInterface } from '../../interfaces/filter-set-saved.interface';

@Component({
  selector: 'create-project-folder',
  templateUrl: './save-filter-set.component.html',
  styleUrls: ['./save-filter-set.component.scss'],
})
export class SaveFilterSetComponent extends BaseModalDirective implements OnInit {
  public filterSetForm: FormGroup;
  public filterSetSaveStep: FilterSetSaveStepEnum = FilterSetSaveStepEnum.STEP_1;
  public filterSetSaveStepEnum = FilterSetSaveStepEnum;

  @Output() filterSetCreated = new EventEmitter<FilterSetSavedInterface>();

  constructor(
    public modalService: ModalService,
    public languagesService: LanguagesService,
    public saveFilterSetFormService: SaveFilterSetFormService,
  ) {
    super(modalService);
    this.initForm();
  }

  public ngOnInit(): void {
    if (this.filterSet.type !== FilterSetTypeEnum.OWN) {
      this.moveToNextStep(false);
    }
  }

  public get filterSet(): FilterSetModel {
    return this.modalService.object.object;
  }

  public initForm(editable = false): void {
    this.saveFilterSetFormService.submitted = false;
    this.saveFilterSetFormService.pending = false;
    this.filterSetForm = this.saveFilterSetFormService.initForm(editable);
  }

  public get isNameValid(): boolean {
    return this.filterSetForm.get('name')?.value !== '';
  }

  public moveToNextStep(updateName = true): void {
    this.filterSetSaveStep = FilterSetSaveStepEnum.STEP_2;
    this.initForm(true);

    if (updateName) {
      this.filterSetForm.patchValue({
        name: this.filterSet.name,
      });
    }
  }

  public save(): void {
    if (!this.saveFilterSetFormService.pending) {
      this.saveFilterSetFormService.submitted = true;
      this.saveFilterSetFormService.pending = true;

      if (this.filterSetForm.invalid) {
        this.saveFilterSetFormService.pending = false;
        return;
      }

      if (this.filterSetSaveStep === FilterSetSaveStepEnum.STEP_1) {
        this.saveFilterSetFormService.update(this.filterSet).subscribe(
          () => {
            this.filterSetCreated.emit({ filterSet: this.filterSet, isNew: false });
            this.close();
          },
          () => {
            this.saveFilterSetFormService.pending = false;
          },
          () => {
            this.saveFilterSetFormService.pending = false;
          },
        );
      } else {
        this.updateFilterSet();

        this.saveFilterSetFormService.create(this.filterSet).subscribe(
          (filterSet: BackendResponseInterface<FilterSetModel>) => {
            this.filterSetCreated.emit({ filterSet: filterSet.data, isNew: true });
            this.close();
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.saveFilterSetFormService.pending = false;
          },
        );
      }
    }
  }

  private updateFilterSet(): void {
    this.filterSet.name = this.filterSetForm.value.name;
    this.filterSet.isDefault = this.filterSetForm.value.setAsDefault;
  }

  public get modalActiveStepTranslationPrefix(): string {
    return `FilterSets.modals.${
      this.filterSetSaveStep === FilterSetSaveStepEnum.STEP_1 ? 'edit' : 'save'
    }`;
  }

  public get modalHeader(): string {
    return `${this.modalActiveStepTranslationPrefix}.header`;
  }

  public get modalDescription(): string {
    return `${this.modalActiveStepTranslationPrefix}.description`;
  }
}
