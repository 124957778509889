import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { CommonFiltersService } from '../../../../common/services/common-filters.service';
import { FiltersServiceInterface } from '../../../../../core/interfaces/filters-service.interface';
import { FiltersInterface } from '../../../../../shared/troi-data-listing-filters/filters.interface';
import { TroiDropdownListModel } from '../../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { TabInterface } from '../../../../../shared/troi-data-listing-tabs/tab.interface';
import { TabIdsEnum, TabsService } from '../../../../../core/services/tabs.service';
import { BasicFiltersService } from '../../../../../core/services/basic-filters.service';
import { AccountingSettingsService } from '../../../common/services/accounting-settings.service';
import { LanguagesService } from '../../../../../core/services/languages.service';
import { StorageService } from '../../../../../core/storage/storage.service';
import { ParentWindowRef } from '../../../../../core/services/parent-window-ref.service';
import { FooterSettingsService } from '../../../../../core/services/footer-settings.service';
import { TroiFilterSetsNetworkService } from '../../../../../shared/troi-filter-sets/network/troi-filter-sets.network';

@Injectable()
export class FiltersService extends CommonFiltersService implements FiltersServiceInterface {
  constructor(
    private tabsService: TabsService,
    private langService: LanguagesService,
    private translationService: TranslateService,
    private accountingSettingsService: AccountingSettingsService,
    protected localStorage: StorageService,
    public basicFiltersService: BasicFiltersService,
    public settings: AccountingSettingsService,
    public parentWindowRef: ParentWindowRef,
    public footerSettingsService: FooterSettingsService,
    protected filterSetsNetworkService: TroiFilterSetsNetworkService,
  ) {
    super(
      basicFiltersService,
      parentWindowRef,
      footerSettingsService,
      localStorage,
      filterSetsNetworkService,
    );
    this.actualFilters = this.defaultFilterValues();
    this.basicFiltersService.clientsLoaded.subscribe(() => {
      this.actualFilters.dropdownFirst = this.getDefaultClientId();
      this.applyFiltersFromUrl(this.pageUrl);
      this.defaultClientAssigned.next(true);
    });
  }

  resetFilters(clientId?: number) {
    this.actualFilters.filters = this.defaultFilterValues().filters;
    this.actualFilters.search = this.defaultFilterValues().search;
    this.actualFilters.dropdownFirst = clientId || this.getDefaultClientId();
    this.actualFilters.dropdownSecond = this.defaultFilterValues().dropdownSecond;
    this.resetPagination();
  }

  defaultFilterValues(): FiltersInterface {
    return {
      filters: this.activityFilters(),
      search: '',
      dropdownFirst: null,
      dropdownSecond: null,
      currentPage: 1,
      pageSize: this.getPageSize(),
    };
  }

  applyFiltersFromUrl(url) {
    const tempUrl = new URL(url.href);

    this.actualFilters = this.applyCommonUrlFilters(tempUrl, this.actualFilters);

    if (tempUrl.searchParams.get('group')) {
      this.actualFilters.dropdownSecond = tempUrl.searchParams.get('group');
    }
  }

  getSelectedClientId() {
    return this.actualFilters.dropdownFirst;
  }

  generateSecondDropdown(): Array<TroiDropdownListModel> {
    return _.concat(
      [
        {
          label: 'Booking.labels.allAccountGroups',
          value: null,
          active: true,
        },
      ],
      this.accountingSettingsService.buildAccountGroupList(),
    );
  }

  generateTabs(): TabInterface[] {
    return this.tabsService.generateTabs([
      {
        id: TabIdsEnum.ACCOUNT_GROUPS,
        active: false,
      },
      {
        id: TabIdsEnum.ACCOUNT_LIST,
        active: true,
      },
    ]);
  }
}
