import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { environment } from '../../../../../environments/environment';
import { Routes } from '../enums/routes';
import { BookingsDataSelectedInterface } from '../interfaces/bookings-data-selected.interface';

@Injectable()
export class BookingsCommonNetwork {
  public constructor(public http: HttpClient) {}

  getDataForSelected(
    costCenter: string,
    account: string,
    counterAccount: string,
    supplier: string,
    accountGroup: string,
    user: number = null,
    processingStatus: string = null,
  ): Observable<BookingsDataSelectedInterface> {
    const params = new HttpParams({
      fromObject: {
        costCenter,
        account,
        counterAccount,
        supplier,
        accountGroup,
        user: user ? user.toString() : null,
        processingStatus,
      },
    });
    return this.http.get<BookingsDataSelectedInterface>(
      environment.url + Routes.BOOKINGS_SELECTED,
      { params },
    );
  }

  getForSelected(data): Observable<BookingsDataSelectedInterface> {
    const params = {};

    _.forEach(data, (element) => {
      params[element.key] = element.value;
    });

    return this.http.get<BookingsDataSelectedInterface>(
      environment.url + Routes.BOOKINGS_SELECTED,
      { params: new HttpParams({ fromObject: params }) },
    );
  }
}
