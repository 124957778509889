import { Directive, HostListener } from '@angular/core';
import { ModalService } from '../troi-modals/modal.service';

@Directive()
export class BaseModalDirective {
  public constructor(
    public modalService: ModalService,
    private closeOnEsc = true,
    private isSecond = false,
  ) {}

  @HostListener('document:keydown.escape', ['$event'])
  private keyPressed() {
    if (this.closeOnEsc) {
      this.close();
    }
  }

  public close() {
    this.modalService.destroy(this.isSecond);
  }

  public closeSecond() {
    this.modalService.destroy(true);
  }
}
