import { TreeListItemInterface } from '../interfaces/tree-list-item.interface';
import { EventEmitter } from '@angular/core';
import { LanguageTranslationsBackend } from '../../../core/interfaces/languageTranslations.backend';
import * as _ from 'lodash';

export class TreeListItemModel<Type> implements TreeListItemInterface<Type> {
  translatedName = '';
  _type: Type;
  _selected: boolean;
  // _dragged: boolean; TODO: TROR-18921  DRAG & DROP specific
  _hasChildren = true;
  _expanded = false;
  _parent: any;
  _nodes?: any[];
  blocked = false;
  refreshForm: EventEmitter<TreeListItemInterface<Type>> = new EventEmitter<
    TreeListItemInterface<Type>
  >();

  public constructor(
    public _id: string,
    public _name: LanguageTranslationsBackend,
    public _order: number, // TODO: TROR-18921 should be also in base DRAG & DROP interface
  ) {
    this.translatedName = this.name.en;
  }

  set parent(parent) {
    this._parent = parent;
  }

  get parent(): any {
    return this._parent;
  }

  get nodes(): any[] {
    return this._nodes;
  }

  set nodes(nodes: any[]) {
    this._nodes = nodes;
    this.filter();
  }

  get hasChildren(): boolean {
    return this._hasChildren;
  }

  set hasChildren(value: boolean) {
    this._hasChildren = value;
  }

  public filter(): void {
    this._nodes = _.sortBy(this._nodes, ['_type', '_order']);
  }

  get name(): LanguageTranslationsBackend {
    return this._name;
  }

  set name(value: LanguageTranslationsBackend) {
    this._name = value;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get order(): number {
    return this._order;
  }

  set order(value: number) {
    this._order = value;
  }

  isFolder = (): boolean => {
    return true;
  };

  get areChildrenLoaded(): boolean {
    return false;
  }

  get expanded(): boolean {
    return this._expanded;
  }

  set expanded(value: boolean) {
    this._expanded = value;
  }
}
