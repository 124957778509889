<div
  #ApprovalProcess="ngbPopover"
  [ngbPopover]="popContent"
  [popoverTitle]="popTitle"
  container="body"
  popoverClass="troi-popover approval-history approval-process {{
    alignTop && placement === 'left' ? 'align-top' : ''
  }}"
  autoClose="outside"
  triggers="manual"
  [placement]="placement"
>
  <div (click)="toggleOverlay(ApprovalProcess, $event)">
    <ng-content></ng-content>
  </div>
</div>
<ng-template #popTitle>
  <div class="d-flex" *ngIf="approvalHistory">
    <h3 class="approval-status__title">
      {{ 'Approval.labels.approvalStatus' | translate }}:
      <span [ngClass]="approvalsService.getApprovalStatusClass(approvalHistory?.approvalState)">{{
        'Approval.states.' + approvalHistory?.approvalState | translate
      }}</span>
    </h3>
  </div>
</ng-template>
<ng-template #popContent>
  <ul
    *ngFor="let stateChange of approvalHistory?.states | slice: 0:showHistoryValue; let i = index"
    class="approval-status__state-change-list"
  >
    <li class="approval-status__state-change">
      <div *ngIf="stateChange.setDate" class="approval-status__date">
        {{ stateChange.setDate | date: 'dd.MM.yyyy' }}
      </div>
      <div *ngIf="stateChange.level" class="approval-status__level">
        {{ 'Approval.labels.level' | translate }} {{ stateChange.level }}
      </div>
      <div class="approval-status__item" [ngClass]="'approval-status__item--' + stateChange.state">
        <div class="approval-status__person">
          {{ stateChange.setByName }}
        </div>
        <div class="approval-status__state">
          {{ 'Approval.changes.' + stateChange.state | translate }}
        </div>
      </div>
      <div *ngIf="stateChange.reason" class="approval-status__reason">
        {{ stateChange.reason }}
      </div>
    </li>
  </ul>
  <span
    class="approval-status__show-history"
    *ngIf="
      approvalHistory?.states?.length > showHistoryLimit && showHistoryLimit === showHistoryValue
    "
    (click)="showMoreHistory(approvalHistory?.states?.length)"
  >
    {{ 'Approval.labels.showOlderHistory' | translate }}
  </span>
  <span
    class="approval-status__show-history"
    *ngIf="showHistoryValue > showHistoryLimit"
    (click)="showMoreHistory(showHistoryLimit)"
    >{{ 'Approval.labels.hideOlderHistory' | translate }}</span
  >
</ng-template>
