import { Injectable, OnDestroy } from '@angular/core';
import { CommonFiltersService } from '../../common/services/common-filters.service';
import { BasicFiltersService } from '../../../core/services/basic-filters.service';
import { ParentWindowRef } from '../../../core/services/parent-window-ref.service';
import { FooterSettingsService } from '../../../core/services/footer-settings.service';
import { StorageService } from '../../../core/storage/storage.service';
import { FiltersInterface } from '../../../shared/troi-data-listing-filters/filters.interface';
import { FilterElementInterface } from '../../../shared/troi-filter-with-modal/filter-element.interface';
import { Subject, Subscription } from 'rxjs';
import { ClientInterface } from '../../../core/interfaces/client.interface';
import { FiltersBaseInterface } from '../../../core/interfaces/filtersBase.interface';
import { TroiDropdownSelectConfigInterface } from '../../../shared/troi-dropdown-select/interfaces/troi-dropdown-select-config.interface';
import { ProjectListSettingsService } from './project-list-settings.service';
import { SettingsEmitter } from '../../../core/emitters/settings.emitter';
import { SettingsModel } from '../models/settings.model';
import { LanguagesService } from '../../../core/services/languages.service';
import { BaseSettingsDropdownModel } from '../../../core/models/settings/dropdowns/base-settings-dropdown.model';
import { FilterTypeEnum } from '../../../shared/troi-filter-with-modal/filter-type.enum';
import { DataTypeEnum } from '../../../core/enums/data-type.enum';
import { ProjectListNetwork } from '../network/project-list.network';
import { DropdownDataSelectedInterface } from '../interfaces/dropdown-data-selected.interface';
import { DropdownsService } from '../../common/services/dropdowns.service';
import { TroiDropdownListModel } from '../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { TroiFilterSetsNetworkService } from '../../../shared/troi-filter-sets/network/troi-filter-sets.network';
import { FilterSetModel } from '../../../shared/troi-filter-sets/models/filter-set.model';
import { Routes } from '../enum/routes';
import { HttpHeaders } from '@angular/common/http';
import { ModuleInterceptor } from '../../../core/enums/module-interceptor';
import { FilterElementDefaultStateInterface } from '../../../shared/troi-filter-with-modal/filter-element-default-state.interface';
import { FilterSetLoadedInterface } from '../../../shared/troi-filter-sets/interfaces/filter-set-loaded.interface';
import { FilterSetTypeEnum } from '../../../shared/troi-filter-sets/enums/filter-set-type.enum';

@Injectable()
export class FiltersService extends CommonFiltersService implements OnDestroy {
  public haveFiltersChanged = new Subject<FiltersInterface>();
  public areFiltersEdited = false;
  public customersDropdown: TroiDropdownSelectConfigInterface;
  public reloadCustomersData: number;
  public thirdDropdown: TroiDropdownSelectConfigInterface;
  public reloadProjectStatusesData: number;
  public resetQuickFilters: Subject<boolean> = new Subject<boolean>();
  public reloadFilterChips: Subject<FilterElementInterface[]> = new Subject<
    FilterElementInterface[]
  >();
  private clientsLoadedSubscription: Subscription;
  private filterSetsLoadedSubscription: Subscription;
  private filterSetSelectedSubscription: Subscription;
  private emitSettingsSubscription: Subscription;

  // TODO: temporarily removed &approvalDateFrom=&approvalDateTo= from predefined filter set, revert when respective column is ready
  get predefinedFilterSets(): FilterSetModel[] {
    return [
      {
        id: 'predefined-all',
        name: 'FilterSets.predefined.all',
        value:
          `searchPhrase=&client=${this.getDefaultClientId()}&currentPage=1&pageSize=100&projectManager=` +
          '&showInactiveFolderProjects=false&showOnlyFavorites=false&customer=*&projectStatus=*' +
          '&projectType=&startAndEndDateFrom=&startAndEndDateTo=&reportingDateFrom=&reportingDateTo=' +
          '&orderingDateFrom=&orderingDateTo=&showInactiveFolderProjects=false' +
          '&showOnlyFavorites=false',
        type: FilterSetTypeEnum.ALL,
        isDefault: false,
        filters: new FiltersInterface(),
      },
      // TODO: temporarily removed '&approvalDateFrom=&approvalDateTo=' + from predefined filter set, revert when respective column is ready
      {
        id: 'predefined-favourite',
        name: 'FilterSets.predefined.favourites',
        value:
          `searchPhrase=&client=${this.getDefaultClientId()}&currentPage=1&pageSize=100&sortDir=undefined&sortBy=undefined&projectType=` +
          '&projectManager=&startAndEndDateFrom=&startAndEndDateTo=' +
          '&reportingDateFrom=&reportingDateTo=' +
          '&orderingDateFrom=&orderingDateTo=&showInactiveFolderProjects=false' +
          '&showOnlyFavorites=true&customer=&projectStatus=',
        type: FilterSetTypeEnum.FAVOURITES,
        isDefault: true,
        filters: new FiltersInterface(),
      },
    ];
  }

  constructor(
    public basicFiltersService: BasicFiltersService,
    public parentWindowRef: ParentWindowRef,
    public footerSettingsService: FooterSettingsService,
    protected localStorage: StorageService,
    private projectListSettingsService: ProjectListSettingsService,
    private settingsEmitter: SettingsEmitter,
    private languagesService: LanguagesService,
    private projectListNetwork: ProjectListNetwork,
    private dropdownService: DropdownsService,
    protected filterSetsNetworkService: TroiFilterSetsNetworkService,
  ) {
    super(
      basicFiltersService,
      parentWindowRef,
      footerSettingsService,
      localStorage,
      filterSetsNetworkService,
      false,
    );
    this.customersDropdown = FiltersService.initQuickFilterMultiSelectDropdown();
    this.thirdDropdown = FiltersService.initQuickFilterMultiSelectDropdown();

    this.actualFilters = this.defaultFilterValues;
    this.pageUrl = this.parentWindowRef.nativeWindow.location;

    this.filterSetsNetworkService.route = Routes.FILTER_SETS;
    this.filterSetsNetworkService.headers = new HttpHeaders().set(
      ModuleInterceptor.PROJECT_LIST,
      '1',
    );

    this.subscribeToClientsLoaded();
    this.subscribeToFilterSetsLoaded();
    this.subscribeToFilterSetSelected();
    this.subscribeToEmitSettings();
  }

  public static stringifyFilterParam(value: string | number): string {
    return String(value);
  }

  private static initQuickFilterMultiSelectDropdown(): TroiDropdownSelectConfigInterface {
    return {
      values: [],
      search: true,
      multiple: true,
      disable: true,
      selectAllOption: true,
    };
  }

  private subscribeToClientsLoaded(): void {
    this.clientsLoadedSubscription = this.basicFiltersService.clientsLoaded.subscribe(() => {
      this.actualFilters.dropdownFirst = this.getDefaultClientId();
      this.loadFilterSets();
    });
  }

  private subscribeToFilterSetsLoaded(): void {
    this.filterSetsLoadedSubscription = this.filterSetsLoaded.subscribe(
      (loadedFilterState: FilterSetLoadedInterface) => {
        if (loadedFilterState.initialLoad) {
          this.applyFiltersFromUrl(
            this.mergeFilterSetWithUrl(loadedFilterState.filterSet.value).toString(),
          );
        } else {
          this.applyFiltersFromUrl(loadedFilterState.filterSet.value);
        }

        this.assignActualFiltersToFilterSet(loadedFilterState.filterSet.id);
        this.defaultClientAssigned.next(true);
      },
    );
  }

  private restoreActualFiltersFromLS(key: string): void {
    const returnFromProjectBaseDataFilters = localStorage.getItem(key);
    if (returnFromProjectBaseDataFilters) {
      this.actualFilters = JSON.parse(returnFromProjectBaseDataFilters);
    }
    localStorage.removeItem(key);
  }

  private subscribeToFilterSetSelected(): void {
    this.filterSetSelectedSubscription = this.filterSetSelected.subscribe(
      (filterSet: FilterSetModel) => {
        this.applyFiltersFromUrl(filterSet.value);
        this.generateCustomersDropdown(this.basicFiltersService.clients);
        this.reloadProjectStatusesData = Math.random();
        this.assignActualFiltersToFilterSet(filterSet.id);
      },
    );
  }

  private subscribeToEmitSettings(): void {
    this.emitSettingsSubscription = this.settingsEmitter
      .getEmitter()
      .subscribe((data: SettingsModel) => {
        this.thirdDropdown = {
          ...this.thirdDropdown,
          values: data.projectStatuses.map(this.mapProjectStatusToDropdownOption),
          selectAllOptionLabel: 'ProjectList.labels.filters.projectStatus.allSelected',
          placeholder: 'ProjectList.labels.filters.projectStatus.placeholder',
          disable: !this.clientId || data.projectStatuses.length <= 1,
        };

        this.reloadProjectStatusesData = Math.random();
      });
  }

  private get defaultFilterValues(): FiltersInterface {
    return {
      filters: this.defaultFilters(),
      search: '',
      dropdownFirst: null,
      dropdownSecond: null,
      dropdownThird: null,
      currentPage: 1,
      pageSize: this.getPageSize(),
    };
  }

  public resetFilters(): void {
    this.actualFilters = {
      ...this.actualFilters,
      filters: this.defaultFilterValues.filters,
      search: this.defaultFilterValues.search,
      dropdownFirst: this.clientId,
      dropdownSecond: this.defaultFilterValues.dropdownSecond,
      dropdownThird: this.defaultFilterValues.dropdownThird,
    };

    this.resetQuickFilters.next(true);
    this.resetPagination();
  }

  private defaultFilters(): FilterElementInterface[] {
    return [
      {
        type: FilterTypeEnum.DROPDOWN_LAZY,
        label: 'ProjectList.labels.filters.projectType.label',
        formName: 'projectType',
        value: '',
        defaultValue: '',
        chips: [
          {
            label: 'ProjectList.labels.filters.projectType.label',
            value: '',
            valueIsTranslatable: true,
          },
        ],
        dataType: DataTypeEnum.PROJECT_TYPES,
        dropdownData: [],
        dropdownSelectAllOption: true,
        dropdownSelectAllOptionLabel: 'ProjectList.labels.filters.projectType.allSelected',
        dropdownMultipleSelect: true,
        dropdownReturnSelectedObject: true,
        pageSize: 100,
        preloadedOptions: [
          {
            active: true,
            disabled: false,
            label: 'ProjectList.labels.filters.projectType.allSelected',
            value: '*',
          },
        ],
        withSearch: false,
        icon: 'icon-files-tree',
      },
      {
        type: FilterTypeEnum.DROPDOWN_LAZY,
        label: 'ProjectList.labels.filters.projectManager.label',
        formName: 'projectManager',
        value: '',
        defaultValue: '',
        chips: [
          {
            label: 'ProjectList.labels.filters.projectManager.label',
            value: '',
            valueIsTranslatable: true,
          },
        ],
        dataType: DataTypeEnum.PROJECT_LEADERS,
        dropdownData: [],
        dropdownSelectAllOption: false,
        dropdownSelectAllOptionLabel: 'ProjectList.labels.filters.projectManager.allSelected',
        dropdownMultipleSelect: true,
        dropdownReturnSelectedObject: false,
        pageSize: 100,
        preloadedOptions: [],
        icon: 'icon-empty-person',
      },
      {
        formName: 'startAndEndDate',
        label: 'ProjectList.labels.columns.projectStartEndDate',
        type: FilterTypeEnum.RANGE_DATE,
        value: [null, null],
        defaultValue: [null, null],
        chips: [
          {
            label: 'ProjectList.labels.columns.projectStartEndDate',
            value: undefined,
            valueIsTranslatable: true,
          },
        ],
        icon: 'icon-calendar',
      },
      {
        formName: 'reportingDate',
        label: 'ProjectList.labels.columns.reportingDate',
        type: FilterTypeEnum.RANGE_DATE,
        value: [null, null],
        defaultValue: [null, null],
        chips: [
          {
            label: 'ProjectList.labels.columns.reportingDate',
            value: undefined,
            valueIsTranslatable: true,
          },
        ],
        icon: 'icon-calendar',
      },
      //TROR-19923 - hide approval columns
      // {
      //   formName: 'approvalDate',
      //   label: 'ProjectList.labels.columns.approvalDate',
      //   type: FilterTypeEnum.RANGE_DATE,
      //   value: [null, null],
      //   defaultValue: [null, null],
      //   chips: [
      //     {
      //       label: 'ProjectList.labels.columns.approvalDate',
      //       value: undefined,
      //       valueIsTranslatable: true,
      //     },
      //   ],
      //   icon: 'icon-calendar',
      // },
      {
        formName: 'orderingDate',
        label: 'ProjectList.labels.columns.orderingDate',
        type: FilterTypeEnum.RANGE_DATE,
        value: [null, null],
        defaultValue: [null, null],
        chips: [
          {
            label: 'ProjectList.labels.columns.orderingDate',
            value: undefined,
            valueIsTranslatable: true,
          },
        ],
        icon: 'icon-calendar',
      },
      {
        type: FilterTypeEnum.GROUP,
        label: '',
        group: {
          singleColumn: true,
          inputAfterLabel: true,
        },
        formName: 'group',
        value: [
          {
            type: FilterTypeEnum.SWITCH,
            label: 'ProjectList.labels.filters.showInactiveFolderProjects.label',
            formName: 'showInactiveFolderProjects',
            value: false,
            defaultValue: false,
            chips: [
              {
                label: 'ProjectList.labels.filters.showInactiveFolderProjects.chipLabel',
                value: 'ProjectList.labels.filters.showInactiveFolderProjects.selected',
                valueIsTranslatable: true,
              },
            ],
            icon: 'icon-radio-button-unchecked',
          },
          {
            type: FilterTypeEnum.SWITCH,
            label: 'ProjectList.labels.filters.showOnlyFavorites.label',
            formName: 'showOnlyFavorites',
            value: false,
            defaultValue: false,
            chips: [
              {
                label: 'ProjectList.labels.filters.showOnlyFavorites.chipLabel',
                value: 'ProjectList.labels.filters.showOnlyFavorites.selected',
                valueIsTranslatable: true,
              },
            ],
            icon: 'icon-black-star',
          },
        ],
      },
    ];
  }

  public generateCustomersDropdown(clients: ClientInterface[]): void {
    const selectedClientId = this.clientId;
    const selectedClient = clients.find(
      (client: ClientInterface) => client.id === selectedClientId,
    );
    const customerValues = selectedClient?.customers
      ? selectedClient.customers.map(this.mapCustomerToDropdownOption)
      : [];

    if (customerValues.length > 0) {
      this.applyCustomerDropdownValues(customerValues);

      return;
    }

    this.projectListNetwork.getCustomers(selectedClientId).subscribe((customersData) => {
      if (customerValues.length < 1) {
        const fallbackValues = customersData.data.map(this.mapCustomerToDropdownOption);

        selectedClient.customers = customersData.data;

        this.applyCustomerDropdownValues(fallbackValues);
      }
    });
  }

  private applyCustomerDropdownValues(
    customerValues: { active: boolean; label: string; value: string; group: boolean }[],
  ) {
    this.customersDropdown = {
      ...this.customersDropdown,
      values: customerValues,
      disable: !customerValues || customerValues.length <= 1,
      selectAllOptionLabel: 'ProjectList.labels.filters.customer.allSelected',
      placeholder: 'ProjectList.labels.filters.customer.placeholder',
    };

    this.reloadCustomersData = Math.random();
  }

  public get clientId(): number {
    return this.getSelectedClientId() ?? this.getDefaultClientId();
  }

  private applyFiltersFromUrl(explicitParams?: string): void {
    this.prepareTempUrl(explicitParams);
    this.fetchSelectedData(explicitParams);
  }

  private prepareTempUrl(explicitParams?: string): URL {
    const tempUrl = explicitParams
      ? new URL(this.pageUrl.href.split('?')[0] + '?page=project_list_2&' + explicitParams)
      : new URL(this.pageUrl.href);

    this.actualFilters = this.applyCommonUrlFilters(tempUrl, this.actualFilters, true, true);

    const customer = tempUrl.searchParams.get('customer');
    if (tempUrl.searchParams.has('customer')) {
      if (customer) {
        this.actualFilters.dropdownSecond = customer === '*' ? customer : customer.split(';');
      } else {
        this.actualFilters.dropdownSecond = [];
      }
    }

    const projectStatus = tempUrl.searchParams.get('projectStatus');
    if (tempUrl.searchParams.has('projectStatus')) {
      if (projectStatus) {
        this.actualFilters.dropdownThird =
          projectStatus === '*'
            ? projectStatus
            : projectStatus.split(';').map((statusId: string) => Number(statusId));
      } else {
        this.actualFilters.dropdownThird = [];
      }
    }

    this.setFilterFromUrl(tempUrl, 'projectType');
    this.setFilterFromUrl(tempUrl, 'projectManager');
    this.setDateFilterFromUrl(tempUrl, 'startAndEndDate');
    this.setDateFilterFromUrl(tempUrl, 'reportingDate');
    //TROR-19923 - hide approval columns
    // this.setDateFilterFromUrl(tempUrl, 'approvalDate');
    this.setDateFilterFromUrl(tempUrl, 'orderingDate');
    this.setFilterFromUrl(tempUrl, 'showInactiveFolderProjects', true);
    this.setFilterFromUrl(tempUrl, 'showOnlyFavorites', true);

    return tempUrl;
  }

  private getFilterValueByDataType(dataType: string) {
    const filterValue = this.actualFilters.filters.find((filter) => filter.dataType === dataType)
      ?.value;
    if (filterValue && typeof filterValue === 'object') {
      return filterValue.map((value) => value.value || value).join(';');
    }
    if (filterValue && typeof filterValue === 'string') {
      return filterValue;
    }
    return '';
  }

  protected fetchSelectedData(explicitParams?: string): void {
    this.restoreActualFiltersFromLS('actualFilters');
    const tempUrl = explicitParams
      ? new URL(this.pageUrl.href.split('?')[0] + '?page=project_list_2' + explicitParams)
      : new URL(this.pageUrl.href);

    const actualProjectTypeValue = this.getFilterValueByDataType('PROJECT_TYPES');
    const actualProjectManagerValue = this.getFilterValueByDataType('PROJECT_LEADERS');

    const projectType = actualProjectTypeValue || tempUrl.searchParams.get('projectType') || '';
    const projectManager =
      actualProjectManagerValue || tempUrl.searchParams.get('projectManager') || '';

    this.projectListNetwork.getDropdownSelectedData(projectType, projectManager).subscribe(
      (response: DropdownDataSelectedInterface) => {
        if (response.projectType) {
          const selectedOptions = this.dropdownService.buildOptionList(
            response.projectType.items,
            DataTypeEnum.PROJECT_TYPES,
          );

          const flattenedSelectedOptions = selectedOptions.reduce(
            (acc: TroiDropdownListModel[], option: TroiDropdownListModel) => {
              if (option.groupValues) {
                acc = acc.concat(option.groupValues);
              }
              return acc;
            },
            [],
          );

          this.setDropdownFilterValue(
            'projectType',
            selectedOptions,
            flattenedSelectedOptions,
            projectType,
          );
        }

        if (response.projectManager) {
          const selectedOptionsData = this.dropdownService.buildOptionList(
            response.projectManager.items,
            DataTypeEnum.EMPLOYEES,
          );
          const selectedOptionsValue = response.projectManager.items.map((option) => option.id);

          this.setDropdownFilterValue(
            'projectManager',
            selectedOptionsData,
            selectedOptionsValue,
            projectManager,
          );
        }

        this.haveFiltersChanged.next(this.actualFilters);
        this.reloadFilterChips.next(this.actualFilters.filters);
      },
      () => {
        this.setDropdownFilterValue('projectType', [], '', projectType);
        this.setDropdownFilterValue('projectManager', [], '', projectManager);
      },
    );
  }

  private setDropdownFilterValue(
    filterName: string,
    data: TroiDropdownListModel[],
    value: string | TroiDropdownListModel[],
    urlValue: string,
  ): void {
    const filter = this.actualFilters.filters[this.getFilterParamIndex(filterName)];
    filter.value = urlValue === '*' ? '*' : value;
    filter.preloadedOptions = data;
  }

  private mapCustomerToDropdownOption(customer: FiltersBaseInterface) {
    return {
      label: customer.name,
      value: customer.id,
      active: true,
      group: false,
    };
  }

  private mapProjectStatusToDropdownOption = (projectStatus: BaseSettingsDropdownModel) => ({
    label: this.languagesService.getLanguageValue(projectStatus.name),
    value: projectStatus.id,
    active: true,
    group: false,
  });

  private mergeFilterSetWithUrl(filterSetValue: string): string {
    const initialUrlSearchParams = new URL(this.pageUrl.href).searchParams;
    const filterSetUrlSearchParams = new URL(
      this.pageUrl.href.split('?')[0] + '?page=project_list_2&' + filterSetValue,
    ).searchParams;
    const mergedUrlSearchParams = new URL(
      this.pageUrl.href.split('?')[0] + '?page=project_list_2&' + filterSetValue,
    ).searchParams;
    const defaultFiltersState = this.getDefaultFiltersState(this.defaultFilters());

    defaultFiltersState.push(
      ...[
        {
          formName: 'client',
          defaultValue: String(this.getDefaultClientId()),
        },
        {
          formName: 'customer',
          defaultValue: '',
        },
        {
          formName: 'projectStatus',
          defaultValue: '',
        },
      ],
    );

    defaultFiltersState.forEach((filterElemDefaultState: FilterElementDefaultStateInterface) => {
      if (
        initialUrlSearchParams.has(filterElemDefaultState.formName) &&
        initialUrlSearchParams.get(filterElemDefaultState.formName) !==
          filterSetUrlSearchParams.get(filterElemDefaultState.formName) &&
        initialUrlSearchParams.get(filterElemDefaultState.formName) !==
          filterElemDefaultState.defaultValue
      ) {
        mergedUrlSearchParams.set(
          filterElemDefaultState.formName,
          initialUrlSearchParams.get(filterElemDefaultState.formName),
        );
      }
    });

    if (mergedUrlSearchParams.toString() !== filterSetUrlSearchParams.toString()) {
      this.areFiltersEdited = true;
    }

    return mergedUrlSearchParams.toString();
  }

  public ngOnDestroy(): void {
    this.clientsLoadedSubscription.unsubscribe();
    this.filterSetsLoadedSubscription.unsubscribe();
    this.filterSetSelectedSubscription.unsubscribe();
    this.emitSettingsSubscription.unsubscribe();
  }
}
