import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FilterSetModel } from '../../../models/filter-set.model';
import { Observable } from 'rxjs';
import {
  BackendResponseInterface,
  ResponseStatusInterface,
} from '../../../../../core/interfaces/backend.response.interface';
import { TroiFilterSetsNetworkService } from '../../../network/troi-filter-sets.network';

@Injectable()
export class SaveFilterSetFormService {
  public submitted = false;
  public pending = false;

  constructor(private filterSetsNetwork: TroiFilterSetsNetworkService) {}

  public initForm(isEditable = true): FormGroup {
    return new FormGroup(
      isEditable
        ? {
            name: new FormControl('', Validators.required),
            setAsDefault: new FormControl(true),
          }
        : {},
    );
  }

  public create(data: FilterSetModel): Observable<BackendResponseInterface<FilterSetModel>> {
    return this.filterSetsNetwork.saveFilterSet(data);
  }

  public update(data: FilterSetModel): Observable<ResponseStatusInterface> {
    return this.filterSetsNetwork.updateFilterSet(data);
  }
}
