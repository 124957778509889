import { CurrencyInterface } from '../../../core/interfaces/currency.interface';
import { Currency, CurrencyStorage } from '../../../shared/troi-money/currency';
import { MoneyFormat } from '../../../shared/troi-money/moneyFormat';
import { SettingsResponseInterface } from '../../../core/interfaces/settings/settings-response.interface';
import { SettingsServiceInterface } from '../../../core/interfaces/settings/settings-service.interface';
import { StorageKeys } from '../../../core/storage/storage.keys';
import { StorageService } from '../../../core/storage/storage.service';
import { Observable, Subscriber } from 'rxjs';
import { BackendResponseInterface } from '../../../core/interfaces/backend.response.interface';
import { BasicSettingsNetwork } from '../../../core/network/basic-settings.network';
import { SettingsEmitter } from '../../../core/emitters/settings.emitter';
import { map } from 'rxjs/operators';

export abstract class BaseSettingsModel<T extends SettingsResponseInterface>
  implements SettingsServiceInterface<T> {
  public systemCurrency: Currency;
  public settings: T;
  public storageKey: StorageKeys;

  constructor(
    protected storageService: StorageService,
    public basicSettingsNetwork: BasicSettingsNetwork,
    protected settingsEmitter: SettingsEmitter,
  ) {
    this.settings = this.getSettingsFromLS();
  }

  public getSettingsFromLS(): T {
    return this.storageService.getItem(this.storageKey) as T;
  }

  public saveSettingsToLS(settings: T): void {
    this.settings = settings;
    this.storageService.setItem(this.storageKey, this.settings);
  }

  public getSettingsRoute(): string {
    return '';
  }

  public downloadSettings(clientId: number): Observable<T> {
    this.basicSettingsNetwork.setRoute(this.getSettingsRoute());
    return this.basicSettingsNetwork.downloadSettings(clientId).pipe(
      map((response: BackendResponseInterface<T>) => {
        this.settings = this.buildSettings(response.data);
        this.saveSettingsToLS(this.settings);

        if (!this.hasFooterCurrencyProperties) {
          this.prepareSettingCurrency();
        }

        return this.settings;
      }),
    );
  }

  public buildSettings(settingsData: SettingsResponseInterface): T {
    return settingsData as T;
  }

  public prepareSettings(clientId: number, force = false): Observable<T> {
    const settings = this.getSettingsFromLS();
    this.storageService.removeItem(this.storageKey);

    if (
      force ||
      !settings ||
      (!settings && !this.areSettingsForClient(clientId, settings.client))
    ) {
      return this.downloadSettings(clientId);
    }

    this.saveSettingsToLS(settings);
    this.settings = settings;
    if (settings.activeCurrency) {
      this.saveActiveCurrency(settings.activeCurrency);
    }
    if (this.settings.dropdowns?.systemCurrency) {
      this.setSystemCurrency(this.settings.dropdowns.systemCurrency, this.settings.client);
    }
    return !this.hasFooterCurrencyProperties
      ? this.prepareSettingCurrency()
      : new Observable((observer: Subscriber<T>) => {
          observer.next(settings);
        });
  }

  public areSettingsForClient = (clientId: number, settingClient: number) => {
    return clientId === settingClient;
  };

  private get hasFooterCurrencyProperties(): boolean {
    return ['activeCurrency', 'currencies'].every((property: string) =>
      this.settings.hasOwnProperty(property),
    );
  }

  public prepareSettingCurrency(getLanguagesFromSettings = true): Observable<T> {
    this.settingsEmitter.setSettings({
      currencies: this.buildCurrencies(this.settings.dropdowns.currencies, this.settings.client),
      activeCurrency: this.buildCurrency(
        this.settings.dropdowns.systemCurrency,
        this.settings.client,
      ),
      availableLanguagesForNavigator: getLanguagesFromSettings
        ? this.settings.settings.availableLanguagesForNavigator
        : [],
      availableLanguagesForData: getLanguagesFromSettings
        ? this.settings.settings.availableLanguagesForData
        : [],
    });

    this.setSystemCurrency(this.settings.dropdowns.systemCurrency, this.settings.client);

    return new Observable((observer: Subscriber<T>) => {
      observer.next(this.settings);
    });
  }

  public setSystemCurrency(currencyData: CurrencyInterface, client: number): void {
    this.systemCurrency = this.buildCurrency(currencyData, client);
  }

  public buildCurrency(currencyData: CurrencyInterface, client: number): Currency {
    return new Currency(
      client,
      currencyData.id,
      currencyData.symbol,
      currencyData.name,
      new MoneyFormat(
        currencyData.decimalMark,
        currencyData.thousandsMark,
        currencyData.isSymbolBehind,
        currencyData.precision,
      ),
      currencyData.exchangeRate,
      currencyData.isSystem,
    );
  }

  public buildCurrencies(currencyData: CurrencyInterface[], client: number): Currency[] {
    return currencyData.map(
      (element: CurrencyInterface): Currency => this.buildCurrency(element, client),
    );
  }

  saveActiveCurrency(activeCurrency: Currency): void {
    const currencyData: CurrencyStorage = {
      client: activeCurrency.client.toString(),
      currency: activeCurrency.id.toString(),
    };
    this.storageService.setItem(StorageKeys.ACTIVE_CURRENCY, currencyData);
  }
}
