import { Component, EventEmitter, Output } from '@angular/core';
import { ModalService } from '../../../../shared/troi-modals/modal.service';
import { StorageNotificationService } from '../../../../core/notifications/storageNotification.service';
import { BaseModalDirective } from '../../../../shared/troi-base-modal/baseModal.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'cancel-approval',
  templateUrl: './cancel-approval.component.html',
  styleUrls: ['./cancel-approval.component.scss'],
  providers: [],
})
export class CancelApprovalComponent extends BaseModalDirective {
  public createForm = new FormGroup({
    reason: new FormControl('', [Validators.required]),
  });
  @Output() parent;
  public modalObject: any;
  public reason = '';
  public canBeDeleted = true;

  public submitted = false;

  public constructor(
    public modalService: ModalService,
    private notificationService: StorageNotificationService,
  ) {
    super(modalService, false, true);
    this.modalObject = this.modalService.object;
  }

  public cancelApproval() {
    const reason = this.createForm.get('reason').value;
    this.parent.cancelApproval(reason);
    this.closeSecond();
  }

  public cannotBeCancelled(): boolean {
    return !this.canBeDeleted;
  }
}
