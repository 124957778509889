export enum UploadRoutes {
  UPLOADED_FILES_LIST = '/components/booking/files',
  UPLOADED_FILES_MOVE = '/components/booking/files/move',
  UPLOADED_FILES_DELETE = '/components/booking/files/delete',
  UPLOADED_FILE = '/components/booking/file',
  UPLOADED_FILE_GROUP = '/components/booking/fileGroup',
  UPLOAD_INVOICE = '/components/booking/files/upload',
  EXPENSE_IMPORT_FILE = '/components/booking/files/expense-import',
  IMPORT_FILE = '/components/booking/files/upload-import',
  IMPORT_BOOKING_RECEIPTS = '/components/booking/booking-receipt/import',
}
