import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ElementRef,
  OnChanges,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { TroiDropDownCloseService } from './services/troi-dropdown-close.service';
import { TroiDropdownListModel } from './models/troi-dropdown-list.model';
import { DropdownOptionSelectedEvent } from './interfaces/dropdown-option-selected-event.interface';
import { DropdownActionInterface } from './interfaces/dropdown-action.interface';

@Component({
  selector: 'troi-dropdown-list',
  templateUrl: './troi-dropdown-list.component.html',
  styleUrls: ['./troi-dropdown-list.component.scss'],
})
export class TroiDropdownListComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild('optionList') optionList;

  @ViewChild('optionWrapper') optionWrapper;

  @Input() public options: Array<TroiDropdownListModel> = [];

  @Input() public actions: DropdownActionInterface[];

  @Input() public static = false;

  @Input() public forceOpen = false;

  @Input() public width = '100%';

  @Input() public textAlignment = '';

  @Input() public value: any;

  @Input() public multipleSelect: boolean;

  @Input() public treeMode = false;

  @Input() public top = false;

  @Input() public noMinWith = false;

  @Input() public disable = false;

  @Input() public search = false;

  @Input() public searchPrefix: string;

  @Input() public searchInputType = 'text';

  @Input() public lazyLoading = false;

  @Input() public totalOptions = null;

  @Input() public isLoading = false;

  @Input() public defaultEmptyValue = null;

  @Input() public initLazyOptionOnInit = false;

  @Input() public returnSelectedObject = false;

  @Input() public predefinedOptionsCount = 0;

  @Output() selectedEmitter = new EventEmitter<DropdownOptionSelectedEvent<any>>();

  @Output() openChange = new EventEmitter<boolean>();

  @Output() loadOptions = new EventEmitter<number>();

  @Output() searchEvent = new EventEmitter<Record<string, unknown>>();

  private element: ElementRef;

  public innerValue: any;

  public openState = false;

  public backendSearch = false;

  public lazyLoadedPages = 1;

  public searchPhrase = '';

  public filteredOptions: Array<TroiDropdownListModel>;

  private debounced = _.debounce(this.triggerBackendSearch, 500);

  private navigationKeys = ['ArrowUp', 'ArrowDown', ' '];

  private firstTimeOpened = false;

  private closeSubject: Subscription;

  private filtersRan = false;

  constructor(element: ElementRef, private troiDropDownCloseServiceset: TroiDropDownCloseService) {
    this.element = element;
  }

  public ngOnInit() {
    if (this.lazyLoading && this.lazyLoadedPages === 1 && this.initLazyOptionOnInit) {
      setTimeout(() => {
        this.loadOption();
        this.firstTimeOpened = true;
      }, 50);
    }
  }

  private activeOptions(): Array<TroiDropdownListModel> {
    return this.options.filter((option) => option.active && !option.disabled);
  }

  public onClickOutside(): void {
    if (this.openState) {
      this.hideList();
    }
  }

  public onClickSelect(event): void {
    this.troiDropDownCloseServiceset.close(event);
    this.toggleList();
    event.stopPropagation();
  }

  public onClickItem(option, event): void {
    if (!this.multipleSelect && !this.treeMode) {
      this.hideList();
    }

    if (
      !this.treeMode ||
      !(option.groupValues?.length > 0 && !this.isGroupOptionSelectable(option))
    ) {
      const eventPath = event.path || (event.composedPath && event.composedPath());

      if (eventPath) {
        if (eventPath.filter((p) => p.className === 'troi-dropdown-option__actions').length === 0) {
          this.emitClickEvent(option, event);
        }
      } else {
        this.emitClickEvent(option, event);
      }
    } else {
      event.stopPropagation();
    }
  }

  private emitClickEvent(option, event): void {
    this.innerValue = option.value;
    const value = this.returnSelectedObject ? option : option.value;
    this.selectedEmitter.emit({ value, event });
    event.stopPropagation();
  }

  private toggleList(): void {
    if (!this.openState) {
      this.addClickSubscription();
      if (this.backendSearch && this.filtersRan) {
        this.searchPhrase = '';
        this.triggerBackendSearch();
      }
    }

    if (this.lazyLoading && this.lazyLoadedPages === 1 && !this.firstTimeOpened) {
      this.loadOption();
    }
    this.openState = !this.openState;
    this.openChange.emit(this.openState);
    this.initList();
    this.firstTimeOpened = true;
  }

  public isOptionSelected(option) {
    if (this.multipleSelect || this.treeMode) {
      let isSelected = false;
      _.forEach(this.value, (innerValue) => {
        if (
          (this.returnSelectedObject && option.value === innerValue.value) ||
          (!this.returnSelectedObject && option.value === innerValue)
        ) {
          isSelected = true;
        }
      });
      return isSelected;
    }
    return option.value === this.innerValue;
  }

  public isGroupOptionSelectable(option): boolean {
    return (
      !option.disabled && !(this.treeMode && option.multipleChoice && option.maxSelections !== 0)
    );
  }

  public isSubOptionDisabled(parent, option): boolean {
    return (
      option.disabled ||
      (this.treeMode &&
        option.multipleChoiceGroup &&
        !this.isOptionSelected(option) &&
        option.maxSelectionsGroup !== 0 &&
        this.selectedOptionsInGroup(parent) >= option.maxSelectionsGroup)
    );
  }

  public selectedOptionsInGroup(option): number {
    if (!option.multipleChoice || !this.treeMode) {
      return 0;
    }

    return option.groupValues
      .map((subOption) => this.isOptionSelected(subOption))
      .filter((isSubOptionSelected) => isSubOptionSelected).length;
  }

  public hideList(): void {
    this.openState = false;
    this.openChange.emit(this.openState);
    if (!this.backendSearch) {
      this.searchPhrase = '';
    }
    this.filterOptions('');
    this.removeClickSubscription();
  }

  private setFocus(): void {
    setTimeout(() => {
      this.element.nativeElement.querySelectorAll('.troi-dropdown-list__list')[0].focus();
    }, 0);
  }

  private scrollToSelected(): void {
    setTimeout(() => {
      const list = this.element.nativeElement.querySelectorAll('.troi-dropdown-list__list')[0];
      const selected = list.querySelectorAll('.troi-dropdown-option--selected')[0];
      if (list && selected) {
        const scrollDown = selected.offsetTop - list.offsetHeight + selected.offsetHeight;
        if (list.scrollTop < scrollDown) {
          list.scrollTop = scrollDown;
        } else if (list.scrollTop > selected.offsetTop) {
          list.scrollTop = selected.offsetTop;
        }
      }
    }, 0);
  }

  public calculateScrollPosition() {
    const currentScrollValue =
      this.optionList.nativeElement.scrollHeight +
      20 -
      (this.optionWrapper.nativeElement.scrollTop + this.optionWrapper.nativeElement.clientHeight);
    if (
      !this.isLoading &&
      this.lazyLoading &&
      currentScrollValue <= 15 &&
      this.totalOptions > this.options.length - this.predefinedOptionsCount
    ) {
      this.loadOption();
    }
  }

  private loadOption() {
    this.searchEvent.emit({ searchPhrase: this.searchPhrase, page: this.lazyLoadedPages });
    this.backendSearch = this.search;
    this.lazyLoadedPages += 1;
  }

  @HostListener('keydown.space', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (this.search) {
      return;
    }
    this.selectedEmitter.emit({ value: this.innerValue, event });
    this.openState = false;
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('keydown.arrowup', ['$event'])
  onArrowUpHandler(event: KeyboardEvent) {
    this.selectOptionByIndex(this.findIndexOfElement(this.innerValue, this.activeOptions()) - 1);

    this.scrollToSelected();
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('keydown.arrowdown', ['$event'])
  onArrowDownHandler(event: KeyboardEvent) {
    this.selectOptionByIndex(this.findIndexOfElement(this.innerValue, this.activeOptions()) + 1);
    this.scrollToSelected();
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('keydown', ['$event'])
  onKeyDownHandler(event: KeyboardEvent) {
    if (this.search) {
      return;
    }
    if (this.navigationKeys.indexOf(event.key) === -1) {
      const filteredOptions = this.getFilteredOptions(event.key);
      if (
        this.innerValue &&
        filteredOptions.length > 1 &&
        this.findIndexOfElement(this.innerValue, filteredOptions) > -1 &&
        filteredOptions[this.findIndexOfElement(this.innerValue, filteredOptions) + 1]
      ) {
        this.innerValue =
          filteredOptions[this.findIndexOfElement(this.innerValue, filteredOptions) + 1].value;
      } else if (filteredOptions.length > 0) {
        this.innerValue = filteredOptions[0].value;
      }
      this.value = this.innerValue;
      this.scrollToSelected();
    }

    event.preventDefault();
    event.stopPropagation();
  }

  private getFilteredOptions(key): Array<TroiDropdownListModel> {
    return this.activeOptions().filter(
      (option) => option.label[0].toLowerCase() === key.toLowerCase(),
    );
  }

  private findIndexOfElement(element: string, list: Array<TroiDropdownListModel>): number {
    return _.findIndex(list, (option) => {
      return option.value === element;
    });
  }

  private selectOptionByIndex(index: number): void {
    const activeOptions = this.activeOptions();
    if (activeOptions[index]) {
      this.value = activeOptions[index].value;
      this.innerValue = activeOptions[index].value;
    }
  }

  private initList(): void {
    if (this.openState) {
      this.innerValue = this.value;
      this.setFocus();
      if (this.innerValue) {
        this.scrollToSelected();
      }
    }
    this.filteredOptions = [...this.options];
  }

  ngOnChanges(changes) {
    if (changes.forceOpen) {
      this.openState = this.forceOpen;
      this.initList();
    }
    if (changes.options && this.lazyLoading) {
      this.options = [...changes.options.currentValue];
      this.filteredOptions = [...changes.options.currentValue];
      if (
        changes.options.previousValue &&
        changes.options.currentValue &&
        changes.options.currentValue.length < changes.options.previousValue.length
      ) {
        this.resetLazyLoadedPages();
      }
    }
  }

  searchClicked(event): void {
    event.stopPropagation();
    this.openState = true;
  }

  resetLazyLoadedPages() {
    this.lazyLoadedPages = 2;
  }

  triggerBackendSearch() {
    this.innerValue = this.defaultEmptyValue;
    this.lazyLoadedPages = 1;
    this.filteredOptions = [];
    this.options = [];
    this.searchEvent.emit({ searchPhrase: this.searchPhrase, page: this.lazyLoadedPages });
    this.backendSearch = true;
    this.lazyLoadedPages += 1;
    this.filtersRan = this.searchPhrase !== '';
  }

  filterOptions(searchPhrase) {
    if (this.lazyLoading && this.openState) {
      this.debounced();
      return;
    }

    if (!searchPhrase) {
      this.filteredOptions = this.options;
      return;
    }

    let returnNextGroup = false;
    const _options = _.clone(this.options);
    this.filteredOptions = _.reverse(_options).filter((option) => {
      if (returnNextGroup && option.group) {
        returnNextGroup = false;
        return true;
      }
      const foundOption =
        !option.group && option.label.toLowerCase().indexOf(searchPhrase.toLowerCase()) > -1;
      if (foundOption) {
        returnNextGroup = foundOption;
      }
      return foundOption;
    });
    _.reverse(this.filteredOptions);
  }

  removeClickSubscription() {
    if (this.closeSubject) {
      this.closeSubject.unsubscribe();
    }
  }

  addClickSubscription() {
    this.closeSubject = this.troiDropDownCloseServiceset.getEmitter().subscribe((event) => {
      const clickedElement = _.find(event.path, (path) => path === this.element.nativeElement);
      if (!clickedElement) {
        this.hideList();
      }

      event.stopPropagation();
    });
  }

  ngOnDestroy(): void {
    this.removeClickSubscription();
  }
}
