import {
  ProjectOptionInterface,
  SubprojectOptionInterface,
} from '../interfaces/project-list.interface';
import { BookingFormNetwork } from '../networks/booking-form.network';
import { LanguagesService } from '../../../../core/services/languages.service';

export abstract class SearchProjectService {
  search = '';

  page = 1;

  sublistPage = 1;

  pageSize = 10;

  subPageSize = 10;

  projects: ProjectOptionInterface[] = [];

  isLoading = false;

  selectedProject: ProjectOptionInterface;

  extraLoadedSubprojects: SubprojectOptionInterface[] = [];

  selectedSubproject: SubprojectOptionInterface;

  canLoadMore = true;

  canLoadMoreSubprojects = true;

  client: number;

  projectReactivation = false;

  protected constructor(
    public network: BookingFormNetwork,
    public languagesService: LanguagesService,
  ) {}

  initialLoadProjects() {
    this.page = 1;
    this.sublistPage = 1;
    this.loadProjects();
  }

  abstract loadProjects(concat?: boolean);

  incrementPageForProjects() {
    if (!this.canLoadMore || this.isLoading) {
      return;
    }
    this.page += 1;
    this.loadProjects(true);
  }

  incrementPageForSubProjects() {
    if (!this.canLoadMoreSubprojects || this.isLoading) {
      return;
    }
    this.sublistPage += 1;
    this.loadNextSubprojectsForProject();
  }

  loadNextSubprojectsForProject() {
    if (!this.selectedProject) {
      return;
    }
    this.isLoading = true;
    this.network
      .fetchSubprojectList(this.selectedProject.id, this.sublistPage, this.subPageSize)
      .subscribe((result) => {
        this.isLoading = false;
        this.extraLoadedSubprojects = [...this.extraLoadedSubprojects, ...result.items];
        this.canLoadMoreSubprojects =
          this.sublistPage === 1 || result.items.length === this.subPageSize;
      });
  }

  clearValue() {
    this.selectedSubproject = undefined;
    this.selectedProject = undefined;
    this.search = '';
    this.projects = [];
    this.extraLoadedSubprojects = [];
    this.canLoadMore = true;
    this.canLoadMoreSubprojects = true;
  }
}
