import { Component, Input } from '@angular/core';
import { PersonInterface } from './interfaces/person.interface';

@Component({
  selector: 'troi-person',
  templateUrl: './troi-person.component.html',
  styleUrls: ['./troi-person.component.scss'],
})
export class TroiPersonComponent {
  @Input() person: PersonInterface | string;
  @Input() header: string = null;
  @Input() large: boolean;
  @Input() medium: boolean;
  @Input() small: boolean;

  public get isText(): boolean {
    return typeof this.person === 'string';
  }

  public get avatarSrc(): string {
    return this.isText || !this.person['image']
      ? `site/resources5/images/blackboard_avatar_default_small.jpg`
      : `site/${this.person['image']}`;
  }
}
