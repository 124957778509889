export enum ColumnsEnum {
  CUSTOMER_OR_PROJECT_NAME = 'customerOrProjectName',
  PROJECT_NUMBER = 'projectNumber',
  CUSTOMER_CONTACT = 'customerContact',
  PROJECT_LEADER = 'projectLeader',
  PROJECT_STATUS = 'projectStatus',
  REPORTING_DATE = 'reportingDate',
  ORDERING_DATE = 'orderingDate',
  ORDERING_STATUS = 'orderingStatus',
  APPROVAL_STATUS = 'approvalStatus',
  APPROVAL_DATE = 'approvalDate',
  PROJECT_START_END_DATE = 'projectStartEndDate',
  ACTUAL_TO_DEBIT_AMOUNT = 'actualToDebitAmount',
  ACTUAL_TO_DEBIT_QUANTITY = 'actualToDebitQuantity',
  ACTUAL_TO_DEBIT_INTERNAL_AMOUNT = 'actualToDebitInternalAmount',
  ACTUAL_TO_DEBIT_INTERNAL_QUANTITY = 'actualToDebitInternalQuantity',
  TOTAL_CALCULATION = 'totalCalculation',
  TOTAL_SUM_CLOSED_INVOICE = 'totalSumClosedInvoice',
  ACTIONS = 'actions',
}
