<div
  class="troi-input-field"
  [ngClass]="{
    'troi-input-field--disabled': !enabled
  }"
>
  <input
    [ngClass]="{
      'troi-input-field__input--invalid': fieldInvalid && validationEnabled,
      'troi-input-field__input--outline': outline
    }"
    class="troi-input-field__input"
    [ngStyle]="{ height: height, 'text-align': align }"
    [type]="type"
    (keypress)="filterContent($event)"
    [placeholder]="placeholder"
    [attr.data-value]="value"
    ngDefaultControl
  />
  <div class="troi-input-field__error" *ngIf="validationEnabled">
    <span *ngIf="fieldInvalid" title="{{ requiredFieldErrorMessage | translate }}">{{
      requiredFieldErrorMessage | translate
    }}</span>
  </div>
</div>
