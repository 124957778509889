<div class="paginator">
  <troi-icon
    icon="icon-bold-arrow-left"
    [size]="'25px'"
    title="{{ 'Common.labels.firstPage' | translate }}"
    [disabled]="isFirstPage()"
    (click)="!isFirstPage() ? pageChanged.emit(1) : ''"
  ></troi-icon>
  <ul class="paginator__pager">
    <li
      class="paginator__pager__pages"
      [ngClass]="{ 'paginator__pager__pages--active': currentPage == item }"
      (click)="pageChanged.emit(item)"
      *ngFor="let item of pages"
    >
      <span> {{ item }} </span>
    </li>
  </ul>

  <troi-icon
    icon="icon-bold-arrow-right"
    [size]="'25px'"
    title="{{ 'Common.labels.lastPage' | translate }}"
    [disabled]="isLastPage()"
    (click)="!isLastPage() ? pageChanged.emit(pagesCount()) : ''"
  >
  </troi-icon>
</div>
