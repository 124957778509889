import { Component, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'troi-textarea',
  templateUrl: './troi-textarea.component.html',
  styleUrls: ['./troi-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TroiTextareaComponent),
      multi: true,
    },
  ],
})
export class TroiTextareaComponent implements ControlValueAccessor {
  @ViewChild(DefaultValueAccessor, { static: true }) private valueAccessor: DefaultValueAccessor;

  writeValue(obj: any) {
    this.valueAccessor.writeValue(obj);
  }

  registerOnChange(fn: any) {
    this.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any) {
    this.valueAccessor.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.valueAccessor.setDisabledState(isDisabled);
  }
}
