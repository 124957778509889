import { Injectable } from '@angular/core';
import { AccessRightService } from '../../../../core/services/access-right.service';
import { BookingSettingsService } from './booking-settings.service';

@Injectable()
export class BookingAccessService {
  constructor(
    private accessRightService: AccessRightService,
    private settingsService: BookingSettingsService,
  ) {}

  hasReadAccess(): boolean {
    return (
      !this.accessRightService.isNoAccess(
        this.settingsService.settings?.permissions?.createBooking,
      ) &&
      !this.accessRightService.isNoAccess(
        this.settingsService.settings?.permissions?.transferBooking,
      )
    );
  }

  canDeleteBooking(): boolean {
    return this.accessRightService.isWriteAccess(
      this.settingsService.settings?.permissions?.createBooking,
    );
  }

  canCreateBooking(): boolean {
    return (
      this.accessRightService.isWriteAccess(
        this.settingsService.settings?.permissions?.createBooking,
      ) &&
      (this.accessRightService.isWriteAccess(
        this.settingsService.settings?.permissions?.transferBooking,
      ) ||
        this.accessRightService.isReadAccess(
          this.settingsService.settings?.permissions?.transferBooking,
        ))
    );
  }

  canEditBooking(): boolean {
    return this.hasReadAccess();
  }

  canFinalizeBooking(): boolean {
    return this.accessRightService.isWriteAccess(
      this.settingsService.settings?.permissions?.transferBooking,
    );
  }
}
