<div class="troi-textfield">
  <input
    *ngIf="!multiline"
    [(ngModel)]="value"
    class="tnd-text-input"
    [type]="type"
    [placeholder]="placeholder + (placeholder && isRequired() ? ' *' : '')"
    [ngClass]="{
      'tnd-text-input--error': hasError(),
      'tnd-text-input--transparent-background': transparentBackground,
      'tnd-text-input--left-icon': icon && iconSide == 'left',
      'tnd-text-input--right-icon': icon && iconSide == 'right'
    }"
    [disabled]="disabled"
  />
  <div
    *ngIf="multiline"
    contenteditable="true"
    class="tnd-text-input"
    [(ngModel)]="value"
    [ngModelOptions]="{ updateOn: 'blur' }"
    [attr.placeholder]="placeholder + (isRequired() ? ' *' : '')"
    [ngClass]="{
      'tnd-text-input--error': hasError(),
      'tnd-text-input--left-icon': icon && iconSide == 'left',
      'tnd-text-input--right-icon': icon && iconSide == 'right'
    }"
    [disabled]="disabled"
  ></div>
  <troi-icon
    *ngIf="icon"
    [icon]="icon"
    class="tnd-text-input__icon-type"
    [ngClass]="{
      'tnd-text-input__icon-align-right': iconSide === 'right',
      'tnd-text-input__icon-align-left': iconSide === 'left'
    }"
  ></troi-icon>
  <div *ngIf="hasError()" class="tnd-error-msg">
    <div *ngFor="let error of getErrorMessages()">
      {{ error.text | translate: { params: error.params } }}
    </div>
  </div>
</div>
