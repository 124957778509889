import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { BackendResponseDropdownDataInterface } from '../../../core/interfaces/backend.response.interface';
import { environment } from '../../../../environments/environment';
import { DropdownRoutesEnum } from '../enums/dropdown-routes.enum';
import { AccountBookingContextInterface } from '../../bookings/common/interfaces/account-booking-context.interface';
import { map } from 'rxjs/operators';
import { FiltersParamsInterface } from '../interfaces/filters-params.interface';
import { Routes } from '../../project-list/enum/routes';

@Injectable()
export class DropdownsNetwork {
  public constructor(public http: HttpClient) {}

  public fetchDropdownData(
    url: DropdownRoutesEnum | string,
    filterObject = 1,
    filterObjectKey = 'client',
    page: number,
    pageSize: number,
    search: string,
    searchFieldKey = 'name',
    lang = 'de',
    year = null,
    headers?: HttpHeaders,
  ): Observable<BackendResponseDropdownDataInterface<any>> {
    const payload = {
      page: page.toString(),
      pageSize: pageSize.toString(),
      lang,
    };
    payload[searchFieldKey] = search;
    payload[filterObjectKey] = filterObject.toString();

    if (!_.isNull(year)) {
      payload['year'] = year;
    }

    const params = new HttpParams({
      fromObject: payload,
    });

    // // TODO: TROR-19690 Remove when API for project types is ready
    // if (url === DropdownRoutesEnum.PROJECT_TYPES) {
    //   return new Observable<BackendResponseDropdownDataInterface<any>>(
    //     (observer: Subscriber<BackendResponseDropdownDataInterface<any>>) => {
    //       setTimeout(() => {
    //         observer.next({
    //           items: [
    //             {
    //               id: 1,
    //               name: 'Intern',
    //               children: [
    //                 {
    //                   id: 1001,
    //                   name: 'Communication',
    //                   parent: 1,
    //                 },
    //                 {
    //                   id: 1002,
    //                   name: 'Intern project',
    //                   parent: 1,
    //                 },
    //               ],
    //             },
    //             {
    //               id: 2,
    //               name: 'Pitch',
    //               children: [
    //                 {
    //                   id: 2001,
    //                   name: 'Design Guidelines',
    //                   parent: 2,
    //                 },
    //                 {
    //                   id: 2002,
    //                   name: 'Requirements',
    //                   parent: 2,
    //                 },
    //                 {
    //                   id: 2003,
    //                   name: 'Certification',
    //                   parent: 2,
    //                 },
    //               ],
    //             },
    //             {
    //               id: 3,
    //               name: 'Film production',
    //             },
    //             {
    //               id: 4,
    //               name: 'SEO',
    //             },
    //             {
    //               id: 5,
    //               name: 'Social Media',
    //             },
    //             {
    //               id: 6,
    //               name: 'Build material production',
    //               children: [
    //                 {
    //                   id: 6001,
    //                   name: 'Landing page',
    //                   parent: 6,
    //                 },
    //                 {
    //                   id: 6002,
    //                   name: 'Bricks',
    //                   parent: 6,
    //                 },
    //               ],
    //             },
    //           ],
    //           errorMessage: null,
    //           page: {
    //             current_page: 1,
    //             per_page: 10,
    //             total_elements: 42,
    //             total_pages: 5,
    //           },
    //         });
    //       }, 500);
    //     },
    //   );
    // }

    // TODO: TROR-19727 Remove when API for project folders is ready
    // if (url === Routes.DROPDOWN_PROJECT_FOLDER) {
    //   return new Observable<BackendResponseDropdownDataInterface<any>>(
    //     (observer: Subscriber<BackendResponseDropdownDataInterface<any>>) => {
    //       setTimeout(() => {
    //         observer.next({
    //           items: [
    //             {
    //               id: '3f69d83b-3d27-49e0-90db-ed604842447a',
    //               name: 'Folder 1',
    //             },
    //             {
    //               id: '2f69d83b-3d27-33e0-91db-ed604842447a',
    //               name: 'Folder 2',
    //             },
    //             {
    //               id: '1f33d83b-3d27-33e0-92db-ed604822442a',
    //               name: 'Folder 3',
    //             },
    //             {
    //               id: '8f88d83b-3d27-33e0-93db-ed604822888a',
    //               name: 'Folder 4',
    //             },
    //             {
    //               id: '5f55d82b-3d27-33e0-94db-ed604822889d',
    //               name: 'Folder 5',
    //             },
    //             {
    //               id: '6f66d86b-6d27-63e0-95db-ed604822886f',
    //               name: 'Folder 6',
    //             },
    //           ],
    //           errorMessage: null,
    //           page: {
    //             current_page: 1,
    //             per_page: 10,
    //             total_elements: 42,
    //             total_pages: 5,
    //           },
    //         });
    //       }, 500);
    //     },
    //   );
    // }

    return this.http.get<BackendResponseDropdownDataInterface<any>>(environment.url + url, {
      params,
      headers,
    });
  }

  public fetchProjectFolders(
    url: DropdownRoutesEnum | string,
    filterObject = 1,
    filterObjectKey = 'client',
    page: number,
    pageSize: number,
    search: string,
    searchFieldKey = 'name',
    lang = 'de',
    year = null,
    headers?: HttpHeaders,
  ): Observable<BackendResponseDropdownDataInterface<any>> {
    const payload = {
      currentPage: page.toString(),
      pageSize: pageSize.toString(),
      lang,
    };
    payload[searchFieldKey] = search;
    payload[filterObjectKey] = filterObject.toString();

    if (!_.isNull(year)) {
      payload['year'] = year;
    }

    const params = new HttpParams({
      fromObject: payload,
    });

    // TODO: TROR-19727 Remove when API for project folders is ready
    // if (url === Routes.DROPDOWN_PROJECT_FOLDER) {
    //   return new Observable<BackendResponseDropdownDataInterface<any>>(
    //     (observer: Subscriber<BackendResponseDropdownDataInterface<any>>) => {
    //       setTimeout(() => {
    //         observer.next({
    //           items: [
    //             {
    //               id: '3f69d83b-3d27-49e0-90db-ed604842447a',
    //               name: 'Folder 1',
    //             },
    //             {
    //               id: '2f69d83b-3d27-33e0-91db-ed604842447a',
    //               name: 'Folder 2',
    //             },
    //             {
    //               id: '1f33d83b-3d27-33e0-92db-ed604822442a',
    //               name: 'Folder 3',
    //             },
    //             {
    //               id: '8f88d83b-3d27-33e0-93db-ed604822888a',
    //               name: 'Folder 4',
    //             },
    //             {
    //               id: '5f55d82b-3d27-33e0-94db-ed604822889d',
    //               name: 'Folder 5',
    //             },
    //             {
    //               id: '6f66d86b-6d27-63e0-95db-ed604822886f',
    //               name: 'Folder 6',
    //             },
    //           ],
    //           errorMessage: null,
    //           page: {
    //             current_page: 1,
    //             per_page: 10,
    //             total_elements: 42,
    //             total_pages: 5,
    //           },
    //         });
    //       }, 500);
    //     },
    //   );
    // }

    return this.http.get<BackendResponseDropdownDataInterface<any>>(environment.url + url, {
      params,
      headers,
    });
  }

  public fetchCPs(
    client: number,
    subproject: number,
    page: number,
    pageSize: number,
    search: string,
    lang = 'de',
  ): Observable<BackendResponseDropdownDataInterface<any>> {
    const payload = {
      page: page.toString(),
      pageSize: pageSize.toString(),
      lang,
      client: client.toString(),
      subProject: subproject.toString(),
      name: search,
    };

    const params = new HttpParams({
      fromObject: payload,
    });
    return this.http.get<BackendResponseDropdownDataInterface<any>>(
      environment.url + DropdownRoutesEnum.CPS,
      { params },
    );
  }

  public fetchAccountsForCreateBooking(
    client: number,
    supplier: string,
    context: string,
    page: number,
    pageSize: number,
    name: string,
    lang = 'de',
  ): Observable<BackendResponseDropdownDataInterface<AccountBookingContextInterface[]>> {
    const payload = {
      page: page.toString(),
      pageSize: pageSize.toString(),
      lang,
      client: client.toString(),
      withSupplier: supplier ? 'true' : 'false',
      context,
      name,
    };

    const params = new HttpParams({
      fromObject: payload,
    });
    return this.http.get<BackendResponseDropdownDataInterface<AccountBookingContextInterface[]>>(
      environment.url + DropdownRoutesEnum.ACCOUNTS_CREATE_BOOKING,
      { params },
    );
  }

  public fetchCPsByKNumber(
    page: number,
    pageSize: number,
    search: string,
    lang = 'de',
    route: string,
    params?: FiltersParamsInterface,
    headers?: HttpHeaders,
  ): Observable<BackendResponseDropdownDataInterface<any>> {
    const baseParams = {
      currentPage: page.toString(),
      pageSize: pageSize.toString(),
      lang,
      kNumber: search,
    };

    const httpParams = new HttpParams({
      fromObject: params
        ? {
            ...params,
            ...baseParams,
          }
        : { ...baseParams },
    });

    const options = headers
      ? {
          params: httpParams,
          headers,
        }
      : { params: httpParams };

    return this.http
      .get<BackendResponseDropdownDataInterface<any>>(`${environment.url}${route}`, options)
      .pipe(
        map((response: BackendResponseDropdownDataInterface<any>) => {
          return {
            ...response,
            items: response.items.map((item) => {
              return {
                ...item,
                number: item.id,
              };
            }),
          };
        }),
      );
  }
}
