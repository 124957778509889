import { Component } from '@angular/core';
import { BaseModalDirective } from '../../../../../shared/troi-base-modal/baseModal.component';
import { ModalService } from '../../../../../shared/troi-modals/modal.service';
import { Options } from '@angular-slider/ngx-slider';
import { SliderValues } from '../../../../../shared/troi-slider/troi-slider.component';
import { BudgedThresholdsService } from './services/budged-thresholds.service';

@Component({
  selector: 'budget-status-settings',
  templateUrl: './budget-status-settings.component.html',
  styleUrls: ['./budget-status-settings.component.scss'],
})
export class BudgetStatusSettingsComponent extends BaseModalDirective {
  public sliderOptions: Options = {
    translate: (value: number): string => `${value}%`,
  };

  constructor(
    public modalService: ModalService,
    public budgetThresholdsService: BudgedThresholdsService,
  ) {
    super(modalService);
    this.budgetThresholdsService.setInitialThresholds();
  }

  public updateThresholdValues(values: SliderValues): void {
    this.budgetThresholdsService.lowWarningPoint = values.lo;
    this.budgetThresholdsService.highWarningPoint = values.hi;
  }

  public save(): void {
    this.budgetThresholdsService.saveThresholds();
    this.close();
  }
}
