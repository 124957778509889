export enum ErrorCodes {
  GLOBAL = 1,
  RESET_PASSWORD_DEFAULT = 1040000,
  RESET_PASSWORD_USER_NOT_FOUND = 1040001,
  RESET_PASSWORD_USER_BLOCKED = 1040002,
  RESET_PASSWORD_PROBLEM_WITH_SENDING_EMAIL = 1040003,
}

export enum SuccessCodes {
  GLOBAL = 1,
  RESET_PASSWORD_SUCCESS,
}

export const ErrorCodeTranslations = {
  [ErrorCodes.GLOBAL]: 'Backend.Error.Global',
  [ErrorCodes.RESET_PASSWORD_USER_NOT_FOUND]: 'Backend.ResettingPassword.Error.UserNotFound',
  [ErrorCodes.RESET_PASSWORD_USER_BLOCKED]: 'Backend.ResettingPassword.Error.UserBlocked',
  [ErrorCodes.RESET_PASSWORD_PROBLEM_WITH_SENDING_EMAIL]: 'Backend.ResettingPassword.Error.Mail',
  [ErrorCodes.RESET_PASSWORD_DEFAULT]: 'Backend.ResettingPassword.Error.General',
};

export const SuccessCodeTranslations = {
  [SuccessCodes.GLOBAL]: 'Backend.Success.Global',
  [SuccessCodes.RESET_PASSWORD_SUCCESS]: 'Backend.ResettingPassword.Success.PasswordReset',
};
