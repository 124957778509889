import * as _ from 'lodash';
import { Currency } from './currency';

export class Money {
  public formattedValue: string;

  private uniqueSeparator = 'uniqueSeparator';

  public numberValue: string;

  public constructor(public value: any, public currency: Currency, public decimalPlaces: number) {
    this.init();
  }

  init() {
    this.numberValue = parseFloat(this.value).toFixed(this.decimalPlaces).toString();
    this.formattedValue = this.formatValue(this.numberValue);
  }

  initWithNewValue(newValue) {
    this.value = newValue;
    this.init();
  }

  get money(): string {
    return this.formattedValue;
  }

  get editableMoney() {
    return _.clone(this.numberValue).replace('.', this.currency.format.decimalMark);
  }

  public update(value: any, zeroIfEmpty = false) {
    if (value) {
      this.numberValue = value.replace(this.currency.format.decimalMark, this.uniqueSeparator);

      this.numberValue = this.parseNumber(this.numberValue);
      this.numberValue = this.numberValue.replace(
        this.uniqueSeparator,
        this.currency.format.decimalMark,
      );
      this.formattedValue = this.formatValue(this.numberValue);
      this.value = this.numberValue.replace(this.currency.format.decimalMark, '.');
    } else if (value === '' && zeroIfEmpty) {
      return this.update('0');
    }

    return this.money;
  }

  public parseNumber(newValue: string): string {
    const splitted = newValue.split(this.uniqueSeparator);
    splitted[0] = splitted[0].replace(/[^\-+\d]/g, '');
    if (!splitted[1]) {
      splitted[1] = '0'.repeat(this.decimalPlaces);
    }
    splitted[1] = splitted[1].replace(/[^\d]/g, '');

    return splitted.join(this.uniqueSeparator);
  }

  get forBackend(): string {
    const value = this.numberValue.replace(this.currency.format.decimalMark, '.');

    return value.startsWith('.') ? `0${value}` : value;
  }

  private formatValue(value: string): string {
    const number = parseFloat(value.replace(this.currency.format.decimalMark, '.')).toFixed(
      this.decimalPlaces,
    );
    const splittedNumber = number.toString().split('.');
    let formattedValue = splittedNumber[0];

    for (let i = splittedNumber[0].length - 3; i > 0; i -= 3) {
      formattedValue =
        formattedValue.slice(0, i) + this.currency.format.thousandsMark + formattedValue.slice(i);
    }

    formattedValue += this.currency.format.decimalMark + splittedNumber[1];

    if (formattedValue.charAt(0) === '-' && formattedValue.charAt(1) === '.') {
      formattedValue = formattedValue.replace('.', '');
    }

    return formattedValue;
  }

  get formattedValueWithCurrencyMark(): string {
    if (this.currency.format.isSymbolBehind) {
      return `${this.formattedValue} ${this.currency.symbol}`;
    }

    return `${this.currency.symbol} ${this.formattedValue}`;
  }

  isZero(): boolean {
    const numberValue = parseFloat('0').toFixed(this.decimalPlaces).toString();

    return this.formattedValue === this.formatValue(numberValue);
  }

  isMinusValue(): boolean {
    return this.formattedValue.charAt(0) === '-';
  }
}
