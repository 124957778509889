<div
  class="troi-person"
  [class.troi-person--large]="large"
  [class.troi-person--medium]="medium"
  [class.troi-person--small]="small"
  *ngIf="person"
>
  <span *ngIf="isText; else objectPerson">{{ person }}</span>
  <ng-template #objectPerson>
    <img class="troi-person__avatar mr-2" [src]="avatarSrc" alt="person" />
    <div *ngIf="header; else withoutHeader" class="troi-person__name">
      <span>{{ header }}</span>
      <p>{{ person.firstName }}&nbsp;{{ person.lastName }}</p>
    </div>
    <ng-template #withoutHeader
      ><span>{{ person.firstName }}&nbsp;{{ person.lastName }}</span></ng-template
    >
  </ng-template>
</div>
