<div
  class="modal-row"
  [ngClass]="{ 'modal-row--disabled': disabled, 'modal-row--without-label': !withLabel }"
  fxLayout="row"
  [fxLayout.md]="noRwd ? 'row' : 'column'"
>
  <div
    *ngIf="withLabel"
    class="modal-row__label"
    [ngClass.md]="!noRwd && 'modal-row__label--md'"
    ngClass.lg="modal-row__label--lg"
  >
    <ng-content select="[row-label]"></ng-content>
  </div>
  <div
    class="modal-row__value"
    [ngClass.md]="!noRwd && 'modal-row__value--md'"
    ngClass.lg="modal-row__value--lg"
  >
    <ng-content select="[row-value]"></ng-content>
  </div>
</div>
