<div (click)="toggleOpen()" class="filter-section">
  <troi-icon icon="icon-filter" size="11px"></troi-icon>
  <span class="filter-section__label">{{ 'Booking.labels.filter' | translate }}</span>
</div>
<troi-filter-modal
  *ngIf="open"
  (openChange)="onOpen($event)"
  [forceOpen]="forceOpen"
  [filterData]="filterData"
  [currencyMoneyData]="currencyMoneyData"
  (filtersApplied)="emitFiltersApplied($event)"
  (filtersReset)="emitFiltersReset()"
  [year]="year"
  [limitDate]="limitDate"
  [dateFormat]="dateFormat"
  [lang]="lang"
  [client]="client"
  [newDesign]="newDesign"
></troi-filter-modal>
