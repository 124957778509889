import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ProjectListComponent } from './project-list.component';
import { ProjectListSettingsService } from './services/project-list-settings.service';
import { HeaderNotificationModule } from '../header-notification/headerNotification.module';
import { SharedModule } from '../../shared/shared.module';
import { ProjectListSettingsInterceptorProvider } from './interceptors/project-list-settings.interceptor';
import { TreeViewListService } from './modules/tree-view/services/tree-view-list.service';
import { TreeViewListNetworkService } from './modules/tree-view/network/tree-view-list.network';
import { TreeViewListFactory } from './modules/tree-view/factories/tree-view-list.factory';
import { ProjectFactory } from './modules/tree-view/factories/project.factory';
import { ColumnsService } from './services/columns.service';
import { FiltersService } from './services/filters.service';
import { FiltersFormService } from './services/filters-form.service';
import { BudgetStatusSettingsComponent } from './components/modals/budget-status-settings/budget-status-settings.component';
import { BudgedThresholdsService } from './components/modals/budget-status-settings/services/budged-thresholds.service';
import { DropdownSelectLazyModule } from '../../shared/troi-dropdown-select-lazy/dropdown-select-lazy.module';
import { ProjectListNetwork } from './network/project-list.network';
import { ExportListComponent } from './components/modals/export-list/export-list.component';
import { ExportListService } from './components/modals/export-list/services/export-list.service';
import { ListTopBarComponent } from './components/list-top-bar/list-top-bar.component';
import { ListTopBarService } from './components/list-top-bar/services/list-top-bar.service';
import { IncomingInvoiceListComponent } from './components/modals/incoming-invoice-list/incoming-invoice-list.component';
import { IncomingInvoiceListService } from './components/modals/incoming-invoice-list/services/incoming-invoice-list.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { IncomingInvoiceFactory } from './factories/incoming-invoice.factory';
import { ProjectPlanComponent } from './components/modals/project-plan/project-plan.component';
import { InvoiceAssignNumbersComponent } from './components/modals/invoice-assign-numbers/invoice-assign-numbers.component';
import { InvoiceAssignNumbersService } from './components/modals/invoice-assign-numbers/services/invoice-assign-numbers.service';
import { SubProjectListComponent } from './components/modals/invoice-assign-numbers/components/sub-project-list/sub-project-list.component';
import { InvoiceAssignNumberFactory } from './factories/invoice-assign-number.factory';
import { ProjectPlanService } from './components/modals/project-plan/services/project-plan.service';
import { ItemNetworkService } from './modules/tree-view/components/item/services/item.network';
import { FolderUnfoldedStateService } from './modules/tree-view/components/services/folder-unfolded-state.service';
import { ApprovalsModule } from '../approvals/approvals.module';

const appRoutes: Routes = [
  {
    path: '',
    component: ProjectListComponent,
  },
];

const factories = [TreeViewListFactory, ProjectFactory];

@NgModule({
  declarations: [
    ProjectListComponent,
    ListTopBarComponent,
    BudgetStatusSettingsComponent,
    ExportListComponent,
    ProjectListComponent,
    BudgetStatusSettingsComponent,
    ProjectPlanComponent,
    InvoiceAssignNumbersComponent,
    SubProjectListComponent,
    IncomingInvoiceListComponent,
  ],
  imports: [
    RouterModule.forChild(appRoutes),
    CommonModule,
    HeaderNotificationModule,
    SharedModule,
    DropdownSelectLazyModule,
    NgxDatatableModule,
    ApprovalsModule,
  ],
  providers: [
    ProjectListSettingsService,
    ProjectListSettingsInterceptorProvider,
    ProjectListNetwork,
    FiltersService,
    FiltersFormService,
    ColumnsService,
    ExportListService,
    ListTopBarService,
    ProjectPlanService,
    InvoiceAssignNumbersService,
    InvoiceAssignNumberFactory,
    IncomingInvoiceListService,
    IncomingInvoiceFactory,
    TreeViewListService,
    TreeViewListNetworkService,
    FolderUnfoldedStateService,
    BudgedThresholdsService,
    ItemNetworkService,
    ...factories,
  ],
})
export class ProjectListModule {}
