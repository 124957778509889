import { Component, Input } from '@angular/core';
import { DropdownActionInterface } from '../troi-dropdown-list/interfaces/dropdown-action.interface';

@Component({
  selector: 'troi-dropdown-option',
  templateUrl: './troi-dropdown-option.component.html',
  styleUrls: ['./troi-dropdown-option.component.scss'],
})
export class TroiDropdownOptionComponent {
  private _actions: DropdownActionInterface[];
  public actionsIds: number[];

  @Input() public disabled = false;

  @Input() public selected = false;

  @Input() public active = true;

  @Input() public value: any;

  @Input() public set actions(newActions: DropdownActionInterface[]) {
    this.actionsIds = newActions
      ? newActions.filter(this.isActionActive).map((action: DropdownActionInterface) => action.id)
      : [];
    this._actions = newActions;
  }

  public get actions(): DropdownActionInterface[] {
    return this._actions?.filter(this.isActionActive);
  }

  @Input() public icon: string;

  @Input() public label: string;

  @Input() public labelInfo: string;

  @Input() public count: number;

  @Input() public loading: boolean;

  @Input() public group: boolean;

  @Input() public parent = false;

  @Input() public child = false;

  @Input() public isRequired = false;

  @Input() public hasOverride: boolean;

  @Input() public showCheckbox = false;

  @Input() public showRadio = false;

  @Input() public leftPadding = false;

  @Input() public colorizeBackground = false;

  private isActionActive = (action: DropdownActionInterface): boolean => {
    return typeof action.active === 'function' ? action.active(this.value) : action.active;
  };

  public getActionById(actionId: number): DropdownActionInterface {
    return this.actions?.find((action: DropdownActionInterface) => action.id === actionId);
  }

  public getActionIcon(actionId: number): string {
    return this.getActionById(actionId)?.icon;
  }

  public getActionTooltip(actionId: number): string {
    return this.getActionById(actionId)?.tooltip;
  }

  public actionSelected(actionId: number): void {
    this.getActionById(actionId)?.actionSelected(this.value);
  }
}
