import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackendResponseInterface } from '../interfaces/backend.response.interface';
import { environment } from '../../../environments/environment';
import { Routes } from '../routes/routes';
import { ClientInterface } from '../interfaces/client.interface';

@Injectable()
export class BasicFiltersNetwork {
  public constructor(public http: HttpClient) {}

  public getClients(
    withoutCustomers: boolean = false,
  ): Observable<BackendResponseInterface<ClientInterface[]>> {
    const params = new HttpParams({
      fromObject: {
        withoutCustomers: Boolean(withoutCustomers).toString(),
      },
    });

    return this.http.get<BackendResponseInterface<ClientInterface[]>>(
      environment.url + Routes.SETTING_CLIENTS,
      { params },
    );
  }
}
