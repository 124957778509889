import { Injectable } from '@angular/core';
import { ColumnsSelectService } from '../../../shared/troi-columns-selector/columns-select.service';
import { ColumnServiceInterface } from '../../../shared/troi-columns-selector/column-service.interface';
import { ColumnSelectInterface } from '../../../shared/troi-columns-selector/column-select.interface';
import { StorageService } from '../../../core/storage/storage.service';
import { ColumnsEnum } from '../enum/columns.enum';

@Injectable()
export class ColumnsService extends ColumnsSelectService implements ColumnServiceInterface {
  public keyLS = 'project-list';

  defaultColumnList: ColumnSelectInterface[] = [
    {
      id: ColumnsEnum.CUSTOMER_OR_PROJECT_NAME,
      name: 'ProjectList.labels.columns.customerOrProjectName',
      enable: false,
      active: true,
      headerClass: 'row__column--name',
    },
    {
      id: ColumnsEnum.PROJECT_NUMBER,
      name: 'ProjectList.labels.columns.projectNumber',
      enable: false,
      active: true,
    },
    {
      id: ColumnsEnum.CUSTOMER_CONTACT,
      name: 'ProjectList.labels.columns.customerContact',
      enable: true,
      active: false,
    },
    {
      id: ColumnsEnum.PROJECT_LEADER,
      name: 'ProjectList.labels.columns.projectLeader',
      enable: true,
      active: true,
    },
    {
      id: ColumnsEnum.PROJECT_STATUS,
      name: 'ProjectList.labels.columns.projectStatus',
      enable: false,
      active: true,
      headerClass: 'row__column--project-status',
      minWidth: 100,
      maxWidth: 100,
      rowClass: 'tree-view-list-folder__project-status',
    },
    {
      id: ColumnsEnum.REPORTING_DATE,
      name: 'ProjectList.labels.columns.reportingDate',
      enable: true,
      active: false,
    },
    {
      id: ColumnsEnum.ORDERING_DATE,
      name: 'ProjectList.labels.columns.orderingDate',
      enable: true,
      active: false,
    },
    {
      id: ColumnsEnum.ORDERING_STATUS,
      name: 'ProjectList.labels.columns.orderingStatus',
      enable: true,
      active: false,
    },
    //TROR-19923 - hide approval columns
    // {
    //   id: ColumnsEnum.APPROVAL_STATUS,
    //   name: 'ProjectList.labels.columns.approvalStatus',
    //   enable: true,
    //   active: false,
    // },
    // {
    //   id: ColumnsEnum.APPROVAL_DATE,
    //   name: 'ProjectList.labels.columns.approvalDate',
    //   enable: true,
    //   active: false,
    // },
    {
      id: ColumnsEnum.PROJECT_START_END_DATE,
      name: 'ProjectList.labels.columns.projectStartEndDate',
      enable: true,
      active: false,
      headerClass: 'row__column--timeline',
      minWidth: 190,
      maxWidth: 190,
      rowClass: 'tree-view-list-folder__timeline',
    },
    {
      id: ColumnsEnum.ACTUAL_TO_DEBIT_AMOUNT,
      name: 'ProjectList.labels.columns.actualToDebitAmount',
      enable: true,
      active: false,
      headerClass: 'row__column--budget-status',
      minWidth: 180,
      maxWidth: 180,
      rowClass: 'tree-view-list-folder__budget-status-column',
    },
    {
      id: ColumnsEnum.ACTUAL_TO_DEBIT_QUANTITY,
      name: 'ProjectList.labels.columns.actualToDebitQuantity',
      enable: true,
      active: true,
      headerClass: 'row__column--budget-status',
      minWidth: 180,
      maxWidth: 180,
      rowClass: 'tree-view-list-folder__budget-status-column',
    },
    {
      id: ColumnsEnum.ACTUAL_TO_DEBIT_INTERNAL_AMOUNT,
      name: 'ProjectList.labels.columns.actualToDebitInternalAmount',
      enable: true,
      active: false,
      headerClass: 'row__column--budget-status',
      minWidth: 180,
      maxWidth: 180,
      rowClass: 'tree-view-list-folder__budget-status-column',
    },
    {
      id: ColumnsEnum.ACTUAL_TO_DEBIT_INTERNAL_QUANTITY,
      name: 'ProjectList.labels.columns.actualToDebitInternalQuantity',
      enable: true,
      active: false,
      headerClass: 'row__column--budget-status',
      minWidth: 180,
      maxWidth: 180,
      rowClass: 'tree-view-list-folder__budget-status-column',
    },
    {
      id: ColumnsEnum.TOTAL_CALCULATION,
      name: 'ProjectList.labels.columns.totalCalculation',
      enable: true,
      active: true,
    },
    {
      id: ColumnsEnum.TOTAL_SUM_CLOSED_INVOICE,
      name: 'ProjectList.labels.columns.totalSumClosedInvoice',
      enable: true,
      active: true,
    },
    {
      id: ColumnsEnum.ACTIONS,
      name: 'ProjectList.labels.columns.actions',
      enable: false,
      active: true,
      headerClass: 'row__column--actions',
      minWidth: 140,
      maxWidth: 140,
      rowClass: 'tree-view-list-folder__actions-column',
    },
  ];

  constructor(public storage: StorageService) {
    super(storage);
  }

  public get activeColumns(): ColumnSelectInterface[] {
    return this.getColumnList().filter((col: ColumnSelectInterface) => col.active);
  }

  getColumnList(): ColumnSelectInterface[] {
    return super.getColumnList(this.defaultColumnList, this.keyLS);
  }

  getColumnListWithoutSome(excludeIds: string[] = []): ColumnSelectInterface[] {
    const columns = super.getColumnList(this.defaultColumnList, this.keyLS);
    columns.forEach((column) => {
      if (excludeIds.includes(column.id)) {
        column.enable = false;
        column.active = false;
      }
    });
    return columns;
  }

  isColumnVisible(columnName): boolean {
    return super.isColumnVisible(this.getColumnList(), columnName, false);
  }

  saveColumnList(columns: ColumnSelectInterface[]): void {
    super.saveColumnList(columns, this.keyLS);
  }
}
