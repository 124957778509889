<div class="filters">
  <div class="filters__title" [ngClass]="newDesign ? 'filters__title--new-design' : ''">
    <troi-icon *ngIf="newDesign" icon="icon-filter" size="25px"></troi-icon>
    <p>{{ 'Booking.labels.filter' | translate }}</p>
  </div>
  <troi-modal-content
    class="filters__content"
    [ngClass]="newDesign ? 'filters__content--new-design' : ''"
  >
    <troi-modal-row *ngFor="let row of _filters">
      <span row-label class="content__label" [ngClass]="{ 'content__label--with-icon': row.icon }">
        <troi-icon *ngIf="row.icon" [icon]="row.icon"></troi-icon>
        {{ row.label | translate }}
      </span>
      <troi-switch row-value small *ngIf="isSwitch(row.type)" [(ngModel)]="row.value"></troi-switch>
      <troi-checkbox
        row-value
        *ngIf="isCheckbox(row.type)"
        (checkboxChange)="toggleItem(row)"
        [value]="row.value"
        [asLabel]="false"
      ></troi-checkbox>
      <troi-input-field
        row-value
        *ngIf="isInput(row.type)"
        [(ngModel)]="row.value"
      ></troi-input-field>
      <troi-range
        row-value
        *ngIf="isRange(row.type)"
        (rangeChanged)="row.value = $event"
        [rangeFrom]="row.value[0]"
        [rangeTo]="row.value[1]"
        [currency]="currencyMoneyData?.currency"
        [decimalPlaces]="currencyMoneyData?.decimalPlaces"
      ></troi-range>
      <troi-dropdown-select
        row-value
        *ngIf="isDropdown(row.type)"
        [noBorder]="false"
        [size]="'11px'"
        class="troi-dropdown-select--full"
        [initValue]="row.value"
        [static]="true"
        (selected)="row.value = $event"
        [options]="row.dropdownData"
      ></troi-dropdown-select>
      <troi-dropdown-select-lazy
        row-value
        *ngIf="isDropdownLazy(row.type)"
        class="filters__troi-dropdown-select-lazy"
        [filterObject]="client"
        [year]="year"
        [lang]="lang"
        [dataType]="row.dataType"
        [initValue]="row.value"
        [defaultEmptyValue]="row.defaultValue"
        (selected)="row.value = $event"
        (optionsLoaded)="updateDropdownData(row, $event)"
        [predefinedOptions]="row.preloadedOptions"
        [withSearch]="row.withSearch"
        [searchPrefix]="row.dropdownPrefix"
        [searchInputType]="row.dropdownInputType ? row.dropdownInputType : 'text'"
        [searchRoute]="row.dropdownSearchRoute"
        [searchParams]="row.dropdownSearchParams"
        [pageSize]="row.pageSize || 10"
        [searchHeaders]="row.dropdownSearchRequestHeader"
        [initLazyOptionOnInit]="row.loadOptionOnInit"
        [selectAllOption]="row.dropdownSelectAllOption"
        [selectAllOptionLabel]="row.dropdownSelectAllOptionLabel"
        [multipleSelect]="row.dropdownMultipleSelect"
        [returnSelectedObject]="row.dropdownReturnSelectedObject"
      ></troi-dropdown-select-lazy>
      <troi-range-date
        row-value
        *ngIf="isRangeDate(row.type)"
        (rangeChanged)="row.value = $event.date"
        [rangeFrom]="row.value[0]"
        [rangeTo]="row.value[1]"
        [year]="year"
        [limitDate]="limitDate"
        [settings]="{ format: dateFormat }"
      ></troi-range-date>
      <troi-range-string
        row-value
        *ngIf="isRangeString(row.type)"
        (rangeChanged)="row.value = $event"
        [rangeFrom]="row.value[0]"
        [rangeTo]="row.value[1]"
        [year]="row.withPlaceholderYear ? year : null"
      ></troi-range-string>
      <div
        row-value
        *ngIf="isGroup(row.type)"
        class="content__grouped-row"
        [ngClass]="{ 'content__grouped-row--single-column': row.group?.singleColumn }"
      >
        <div
          class="grouped-row__item"
          *ngFor="let item of row.value"
          [ngClass]="{ 'grouped-row__item--input-after-label': row.group?.inputAfterLabel }"
        >
          <troi-switch *ngIf="isSwitch(item.type)" [(ngModel)]="item.value" small></troi-switch>
          <troi-checkbox
            *ngIf="isCheckbox(item.type)"
            (checkboxChange)="toggleItem(item)"
            [value]="item.value"
            [asLabel]="false"
          ></troi-checkbox>
          <span>
            <troi-icon *ngIf="item.icon" [icon]="item.icon"></troi-icon>
            {{ item.label | translate }}
          </span>
        </div>
      </div>
    </troi-modal-row>
  </troi-modal-content>
  <troi-modal-footer>
    <troi-btn (click)="emitFiltersReset()" [outline]="true">{{
      'Booking.labels.resetFilters' | translate
    }}</troi-btn>
    <troi-btn (click)="emitFiltersApplied()">{{
      'Booking.labels.applyFilters' | translate
    }}</troi-btn>
  </troi-modal-footer>
</div>
