import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  HostListener,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'troi-inline-edit',
  templateUrl: './troi-inline-edit.component.html',
  styleUrls: ['./troi-inline-edit.component.scss'],
})
export class TroiInlineEditComponent implements OnChanges {
  @Input() public value: string;

  @Input() public ngContentModel: any;

  @Input() public focus = true;

  @Input() public toRight = false;

  @Input() public enabled = true;

  @Input() public widthAuto = false;

  @Output() editModeEmitter = new EventEmitter<boolean>();

  @Output() enterEmitter = new EventEmitter<KeyboardEvent>();

  public editMode = false;

  constructor(private cdRef: ChangeDetectorRef) {}

  @HostListener('click', ['$event'])
  private onClickInside(event) {
    if (this.editMode) {
      event.stopPropagation();
    }
  }

  @HostListener('keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    this.onClickOutside();
    this.enterEmitter.emit(event);
    this.cdRef.detectChanges();
    event.stopPropagation();
  }

  @HostListener('dblclick', ['$event'])
  public onDoubleClick(event: MouseEvent): void {
    if (!this.enabled) {
      return;
    }
    if (this.editMode) {
      this.onClickOutside();
      event.stopPropagation();
      this.cdRef.detectChanges();
      return;
    }
    event.stopPropagation();
    this.activateEdit();
  }

  public onClickOutside(): void {
    if (this.editMode) {
      this.deactivateEdit();
    }
  }

  private activateEdit(): void {
    this.editModeEmitter.emit(true);
    this.editMode = true;
  }

  private deactivateEdit(): void {
    this.editModeEmitter.emit(false);
    this.editMode = false;
  }

  ngOnChanges(): void {
    this.cdRef.detectChanges();
  }
}
