import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  SuccessCodeTranslations,
  ErrorCodeTranslations,
  ErrorCodes,
  SuccessCodes,
} from './codes.translations';

@Injectable()
export class CodesTranslationService {
  public constructor(private translationService: TranslateService) {}

  public getErrorTranslationForCode(
    code: number,
    interpolateParams?: Record<string, unknown>,
    defaultErrorCode?: number,
  ): Observable<string | any> {
    const translationKey = this.existError(code)
      ? ErrorCodeTranslations[code]
      : this.existError(defaultErrorCode)
      ? ErrorCodeTranslations[defaultErrorCode]
      : ErrorCodeTranslations[ErrorCodes.GLOBAL];
    return this.translationService.get(translationKey, interpolateParams);
  }

  public getSuccessTranslationForCode(
    code: number,
    interpolateParams?: Record<string, unknown>,
    defaultSuccessCode?: number,
  ): Observable<string | any> {
    const translationKey = this.existSuccess(code)
      ? SuccessCodeTranslations[code]
      : this.existSuccess(defaultSuccessCode)
      ? SuccessCodeTranslations[defaultSuccessCode]
      : SuccessCodeTranslations[SuccessCodes.GLOBAL];

    return this.translationService.get(translationKey, interpolateParams);
  }

  private existError(code: number) {
    return !_.isUndefined(ErrorCodeTranslations[code]);
  }

  private existSuccess(code: number) {
    return !_.isUndefined(SuccessCodeTranslations[code]);
  }
}
