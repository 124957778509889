import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FooterService } from './services/footer.service';
import { StorageListener } from '../../core/storage/storage.listener';
import { ColorSwitcherService } from '../../core/colorSwitcher/colorSwitcher.service';
import { LanguagesService } from '../../core/services/languages.service';
import { StorageKeys } from '../../core/storage/storage.keys';
import { Currency } from '../troi-money/currency';
import { Colors } from '../../core/colorSwitcher/colors.enum';
import { SettingsEmitter } from '../../core/emitters/settings.emitter';
import { ModalService } from '../troi-modals/modal.service';
import { SettingsModalComponent } from './modal/settings-modal/settings-modal.component';

interface Color {
  color: string;
  selected: boolean;
}

@Component({
  selector: 'troi-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnChanges {
  public footerForm: FormGroup;

  public colors: Color[];

  public languageList = [];

  public dataLanguageList = [];

  public currencyList: Array<Record<string, unknown>> = [];

  private languagePrefix = 'Navigation: ';

  private dataLanguagePrefix = 'Data: ';

  private availableLanguagesForNavigator: string;

  private availableLanguagesForData: string;

  @Input() settings: any;

  @Input() disabledCurrency = false;

  @Input() loggingWorkingTimeEnabled = false;

  @Input() settingsEnabled = false;

  @Output() currencyChanged = new EventEmitter<Currency>();

  constructor(
    private settingsEmitter: SettingsEmitter,
    private translationService: TranslateService,
    private storageListener: StorageListener,
    private route: ActivatedRoute,
    private router: Router,
    public colorSwitcherService: ColorSwitcherService,
    public languageService: LanguagesService,
    public footerService: FooterService,
    public modalService: ModalService,
  ) {
    this.footerService.getTimetrackerSetting().subscribe();
    this.setLanguagePrefixes();
    this.buildForm();
    this.settingsEmitter.getEmitter().subscribe((settings: any) => {
      this.settings = settings;
      this.populateSettings(settings);
    });
    this.storageListener.storageChanges.subscribe((changesData: any) => {
      switch (changesData.key) {
        case StorageKeys.LANG:
          this.footerForm.controls['language'].patchValue(changesData.value);
          setTimeout(async () => {
            this.setLanguagePrefixes();
            this.refreshLanguageList();
          }, 0);
          break;
        case StorageKeys.DATA_LANG:
          this.footerForm.controls['dataLanguage'].patchValue(changesData.value);
          break;
        case StorageKeys.ACTIVE_CURRENCY:
          this.changeCurrency(changesData.value.currency);
          this.populateSettings(this.settings);
          break;
        case StorageKeys.THEME_COLOR:
          this.colorSwitcherService.switchColor(changesData.value);
          break;
        default:
          break;
      }
    });
    this.route.url.subscribe(() => {
      this.footerService.settingsService.changeRouteKey(this.router.url);
    });
  }

  populateSettings(settings: any) {
    this.languageList = this.buildLanguageList(
      settings.availableLanguagesForNavigator,
      this.languagePrefix,
    );
    this.dataLanguageList = this.buildLanguageList(
      settings.availableLanguagesForData,
      this.dataLanguagePrefix,
    );

    if (settings.availableLanguagesForNavigator.length === 0) {
      this.languageList = this.buildLanguageList(
        this.languageService.getSystemLanguages(),
        this.languagePrefix,
      );
    }

    if (settings.availableLanguagesForData.length === 0) {
      this.dataLanguageList = this.buildLanguageList(
        this.languageService.getSystemLanguages(),
        this.dataLanguagePrefix,
      );
    }

    let currentLanguage = this.languageService.getLanguage();
    let currentDataLanguage = this.languageService.getDataLanguage();

    if (
      settings.availableLanguagesForNavigator.length !== 0 &&
      !settings.availableLanguagesForNavigator.includes(currentLanguage)
    ) {
      [currentLanguage] = settings.availableLanguagesForNavigator;
      this.languageService.setLanguage(currentLanguage);
    }

    if (
      settings.availableLanguagesForData.length !== 0 &&
      !settings.availableLanguagesForData.includes(currentDataLanguage)
    ) {
      [currentDataLanguage] = settings.availableLanguagesForData;
      this.languageService.setDataLanguage(currentDataLanguage);
    }

    this.currencyList = this.buildCurrencyList(settings.currencies);
    this.footerForm.controls['currency'].patchValue(settings.activeCurrency.id);
    this.footerForm.controls['language'].patchValue(currentLanguage);
    this.footerForm.controls['dataLanguage'].patchValue(currentDataLanguage);
  }

  setLanguagePrefixes() {
    this.translationService.get('Footer.Navigation').subscribe(() => {
      this.languagePrefix = `${this.translationService.instant('Footer.Navigation')}: `;
    });
    this.translationService.get('Footer.Data').subscribe(() => {
      this.dataLanguagePrefix = `${this.translationService.instant('Footer.Data')}: `;
    });
  }

  selectedLanguage(language) {
    this.languageService.setLanguage(language);
  }

  selectedDataLanguage(language) {
    this.languageService.setDataLanguage(language);
    this.refreshLanguageList();
  }

  refreshLanguageList() {
    setTimeout(() => {
      this.setLanguagePrefixes();
      const _languageList = this.languageList.map((language) => language.value);
      const _dataLanguageList = this.dataLanguageList.map((language) => language.value);
      this.languageList = this.buildLanguageList(_languageList, this.languagePrefix);
      this.dataLanguageList = this.buildLanguageList(_dataLanguageList, this.dataLanguagePrefix);
    }, 0);
  }

  buildForm() {
    this.footerForm = new FormGroup({
      currency: new FormControl(null),
      language: new FormControl(this.languageService.getLanguage()),
      dataLanguage: new FormControl(this.languageService.getDataLanguage()),
    });
    this.colors = this.colorList();

    this.languageList = this.buildLanguageList(['de'], this.languagePrefix);
    this.dataLanguageList = this.buildLanguageList(['de'], this.dataLanguagePrefix);
  }

  changeCurrency(currencyId: string) {
    const currency = _.find(
      this.settings.currencies,
      (currencyObject: Currency) => currencyObject.id === parseInt(currencyId),
    );
    if (currency) {
      this.currencyChanged.emit(currency);
    }
  }

  changeColor() {
    const currentColor = this.colorSwitcherService.color;
    const nextColorIndex = (_.findIndex(this.colors, { color: currentColor }) + 1) % 5;
    this.colorSwitcherService.saveColor(this.colors[nextColorIndex].color);
  }

  buildLanguageList(elements: Array<string>, labelPrefix: string = '') {
    return elements.map((lang) => ({
      active: true,
      value: lang,
      label: labelPrefix + lang,
    }));
  }

  // todo: change object to some interface
  buildCurrencyList(currencies: Currency[]): Array<Record<string, unknown>> {
    const currencyList = [];
    _.forEach(currencies, (currency) => {
      currencyList.push({
        active: true,
        value: currency.id,
        label: currency.symbol,
      });
    });

    return currencyList;
  }

  public addWorkingTime = (): void => {
    window.top['editWorkingTimeLog']();
  };

  public logout = (): void => {
    const url = window.top.location.href;
    window.top.location.replace(`/logout?ref=${encodeURIComponent(url)}`);
  };

  public openSettings() {
    this.modalService.object = this.footerService.settingsService.readFromLS();
    this.modalService.init(SettingsModalComponent);
  }

  private colorList(): Color[] {
    return Object.entries(Colors).map((el) => ({
      color: el[1],
      selected: this.colorSwitcherService.color === el[1],
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.settings &&
      changes.settings.currentValue &&
      changes.settings.currentValue.dropdowns
    ) {
      this.populateSettings({
        currencies: changes.settings.currentValue.dropdowns.currencies,
        activeCurrency: changes.settings.currentValue.dropdowns.systemCurrency,
        availableLanguagesForNavigator:
          changes.settings.currentValue.settings.availableLanguagesForNavigator,
        availableLanguagesForData: changes.settings.currentValue.settings.availableLanguagesForData,
      });
    }
  }
}
