import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as _ from 'lodash';
import { DateFocusOutInterface } from './date.directive';
import { TroiRangeDateSettingsInterface } from './interfaces/troi-range-date-settings.interface';

export interface RangeDateChangedInterface {
  isValid: boolean;
  date: number[];
}

@Component({
  selector: 'troi-range-date',
  templateUrl: './troi-range-date.component.html',
  styleUrls: ['./troi-range-date.component.scss'],
})
export class TroiRangeDateComponent implements OnInit, OnChanges {
  @Input() singleDatePicker = false;

  @Input() disabled = false;

  @Input() public rangeFrom: string;

  @Input() public rangeTo: string;

  @Input() public year: string;

  @Input() public limitDate = false;

  @Input() public isStartOfEndDateDay = false;

  @Input() public fieldInvalid = false;

  @Input() public validationEnabled = false;

  @Input() public emitIfEmpty = false;

  @Input() public requiredFieldErrorMessage = 'Form.Error.Required';

  @Input() public hideRanges = false;

  @Input() public placeholder = '';

  @Input() public readOnly = false;

  @Output() public rangeChanged = new EventEmitter<RangeDateChangedInterface>();

  selected: { startDate: Moment; endDate: Moment } = null;

  ranges: any;

  private _settings: TroiRangeDateSettingsInterface = {
    format: 'DD.MM.YYYY',
    applyLabel: 'Apply',
    clearLabel: 'Clear',
    customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 1,
  };

  @Input() set settings(settings: TroiRangeDateSettingsInterface) {
    const dateFormat = settings.format || this._settings.format;
    this._settings = {
      ...this._settings,
      ...settings,
      format: dateFormat.toUpperCase(),
    };
  }

  get settings(): TroiRangeDateSettingsInterface {
    return this._settings;
  }

  maxDate;

  minDate;

  validDate = true;

  constructor(private translationService: TranslateService) {}

  ngOnInit() {
    this.translationService.get('Booking').subscribe((result) => {
      this.settings.daysOfWeek = _.values(result.daysShort);
      this.settings.monthNames = _.values(result.months);
    });
    this.translationService.get('Common.labels').subscribe((result) => {
      this.prepareRanges(result);
      this.settings.applyLabel = result.apply;
      this.settings.clearLabel = result.clear;
      this.settings.customRangeLabel = result.customRange;
    });

    if (_.isNull(this.rangeFrom) && _.isNull(this.rangeTo)) {
      this.selected = null;
    } else {
      this.selected = {
        startDate: this.prepareDateValue(this.rangeFrom),
        endDate: this.prepareDateValue(this.rangeTo),
      };
    }
    if (this.limitDate) {
      this.setMinMaxDate();
    }
  }

  setMinMaxDate() {
    if (!this.year) {
      this.maxDate = moment().endOf('year');
      this.minDate = moment().startOf('year');
    } else {
      const lastYearDay = `${this.year}-12-31`;
      const firstYearDay = `${this.year}-01-01`;
      this.maxDate = moment(lastYearDay);
      this.minDate = moment(firstYearDay);
    }
  }

  prepareRanges(translationData) {
    this.ranges = {
      [translationData.today]: [moment(), moment()],
      [translationData.yesterday]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      [translationData.last7days]: [moment().subtract(6, 'days'), moment()],
      [translationData.last30days]: [moment().subtract(29, 'days'), moment()],
      [translationData.thisMonth]: [moment().startOf('month'), moment().endOf('month')],
      [translationData.lastMonth]: [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
    };
  }

  dateChanged(event, valid = true) {
    this.rangeChanged.emit({
      isValid: valid,
      date: [
        _.isNull(event.startDate) ? null : event.startDate.startOf('day').unix() * 1000,
        _.isNull(event.endDate)
          ? null
          : (this.isStartOfEndDateDay
              ? event.endDate.startOf('day')
              : event.endDate.endOf('day')
            ).unix() * 1000,
      ],
    });
    this.validDate = true;
  }

  prepareDateValue = (value): Moment => {
    if (_.isNull(value)) {
      return value;
    }

    return moment(value).isValid() ? moment(value) : moment(parseInt(value));
  };

  onDateChange(data: DateFocusOutInterface) {
    if (data.date === '') {
      if (this.emitIfEmpty) {
        this.rangeChanged.emit({
          isValid: false,
          date: [null, null],
        });
      }
      return;
    }
    if (this.singleDatePicker && data.isValid) {
      this.selected = {
        startDate: moment(data.date, this.settings.format),
        endDate: null,
      };
    }
    this.dateChanged(this.selected, data.isValid);
    this.validDate = data.isValid;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rangeFrom && changes.rangeFrom.currentValue) {
      this.selected = {
        startDate: this.prepareDateValue(changes.rangeFrom.currentValue),
        endDate: changes.rangeTo
          ? this.prepareDateValue(changes.rangeTo.currentValue)
          : this.selected
          ? this.selected.endDate
          : null,
      };
    }
  }
}
