<form [formGroup]="filterSetForm" class="save-filter-set">
  <troi-modal-header>
    <span>{{ modalHeader | translate }}</span>
  </troi-modal-header>
  <troi-modal-content class="save-filter-set__content">
    <span class="content__description">
      {{ modalDescription | translate: { filterSetName: filterSet.name } }}
    </span>
    <ng-container *ngIf="filterSetSaveStep === filterSetSaveStepEnum.STEP_2">
      <troi-modal-row>
        <span row-label>{{ 'FilterSets.modals.controls.name.label' | translate }}</span>
        <troi-input-field
          row-value
          formControlName="name"
          placeholder="{{ 'FilterSets.modals.controls.name.placeholder' | translate }}"
          [fieldInvalid]="saveFilterSetFormService.submitted && !isNameValid"
          [validationEnabled]="true"
          requiredFieldErrorMessage="FilterSets.modals.controls.name.required"
        ></troi-input-field>
      </troi-modal-row>
      <troi-modal-row>
        <span row-label>
          {{ 'FilterSets.modals.controls.setAsDefault' | translate }}
        </span>
        <troi-switch
          row-value
          formControlName="setAsDefault"
          [control]="filterSetForm.controls['setAsDefault']"
          small
        ></troi-switch>
      </troi-modal-row>
    </ng-container>
  </troi-modal-content>
  <troi-modal-footer class="save-filter-set__footer">
    <div class="footer__error" *ngIf="saveFilterSetFormService.submitted && !isNameValid">
      {{ 'FilterSets.modals.fillAllRequiredFields' | translate }}
    </div>
    <troi-btn class="footer__button" (click)="close()" [outline]="true" [submit]="false">{{
      'Common.labels.cancel' | translate
    }}</troi-btn>
    <troi-btn
      *ngIf="filterSetSaveStep === filterSetSaveStepEnum.STEP_1"
      class="footer__button footer__button--save footer__button--accent"
      (click)="moveToNextStep()"
      [disabled]="saveFilterSetFormService.pending"
      >{{ 'FilterSets.modals.saveAsNew' | translate }}</troi-btn
    >
    <troi-btn
      class="footer__button footer__button--save"
      (click)="save()"
      [disabled]="saveFilterSetFormService.pending"
      >{{ 'Common.labels.save' | translate }}</troi-btn
    >
  </troi-modal-footer>
</form>
