<label
  *ngIf="asLabel"
  class="troi-checkbox"
  [ngClass]="{
    'troi-checkbox--disabled': disabled,
    'troi-checkbox--checked': _value,
    pointer: !disabled || pointer,
    'troi-checkbox--remove-left-border': removeLeftBorder
  }"
>
  <input
    type="checkbox"
    [(ngModel)]="value"
    (change)="toggleCheckbox($event)"
    [attr.disabled]="disabled || noPropagation ? '' : null"
  />
  {{ label }}
</label>
<label class="container" *ngIf="!asLabel" [ngClass]="{ 'container--disabled': disabled }">
  <input
    type="checkbox"
    [(ngModel)]="value"
    (change)="toggleCheckbox($event)"
    [attr.disabled]="disabled || noPropagation ? '' : null"
  />
  <span
    class="checkmark"
    [ngClass]="{ 'checkmark--accent': accent }"
    [ngStyle]="{ top: topStyle }"
  ></span>
</label>
