import { Component, Input, forwardRef } from '@angular/core';

import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl,
  ControlContainer,
} from '@angular/forms';
import { ErrorTranslationService } from '../../core/language/error.translation.service';

@Component({
  selector: 'troi-textfield',
  templateUrl: './troi-textfield.component.html',
  styleUrls: ['./troi-textfield.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TroiTextfieldComponent),
      multi: true,
    },
  ],
})
export class TroiTextfieldComponent implements ControlValueAccessor {
  @Input() public placeholder = '';

  @Input() public type = 'text';

  @Input() formControl: FormControl;

  @Input() public multiline = false;

  @Input() formControlName: string;

  @Input() public icon = '';

  @Input() public iconSide = 'right';

  @Input() public disabled = false;

  @Input() public transparentBackground = false;

  private innerValue = '';

  private changed = [];

  private touched = [];

  get control() {
    return this.formControl || this.controlContainer.control.get(this.formControlName);
  }

  constructor(
    private controlContainer: ControlContainer,
    private errorTranslationService: ErrorTranslationService,
  ) {}

  isRequired(): boolean {
    return this.control.hasError('required');
  }
  isValid(): boolean {
    return this.control && (this.control.valid || this.control.pristine);
  }

  hasError(): boolean {
    return (
      this.control.invalid && this.control.errors && (this.control.dirty || this.control.touched)
    );
  }

  get value(): string {
    return this.innerValue;
  }

  set value(value: string) {
    let _value;
    if (this.multiline) {
      _value = this.parseValue(value);
    } else {
      _value = value;
    }
    if (this.innerValue !== _value) {
      this.innerValue = _value;
      this.changed.forEach((f) => f(_value));
    }
  }

  private parseValue(value) {
    const container = document.createElement('DIV');
    container.innerHTML = value;
    return container.innerText;
  }
  public getErrorMessages() {
    return this.errorTranslationService.getErrorMessagesForTextfield(this.control);
  }

  writeValue(value: string) {
    this.innerValue = value;
  }

  registerOnChange(fn: (value: any) => void) {
    this.changed.push(fn);
  }

  registerOnTouched(fn: () => void) {
    this.touched.push(fn);
  }

  touch() {
    this.touched.forEach((f) => f());
  }
}
