<button
  class="troi-btn"
  [ngClass]="{
    'troi-btn--disabled': disabled,
    'troi-btn--outline': outline,
    'troi-btn--green': green
  }"
  [disabled]="disabled"
  type="{{ submit ? 'submit' : 'button' }}"
>
  <span class="troi-btn__text">
    <ng-content></ng-content>
  </span>
</button>
