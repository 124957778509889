import { Component, Input } from '@angular/core';

@Component({
  selector: 'troi-btn',
  templateUrl: './troi-btn.component.html',
  styleUrls: ['./troi-btn.component.scss'],
})
export class TroiBtnComponent {
  @Input() public disabled = false;

  @Input() public submit = true;

  @Input() public outline = false;

  @Input() public green = false;
}
