import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendResponseInterface } from '../interfaces/backend.response.interface';
import { environment } from '../../../environments/environment';
import { SettingsResponseInterface } from '../interfaces/settings/settings-response.interface';

@Injectable()
export class BasicSettingsNetwork {
  private route: string;

  public constructor(public http: HttpClient) {}

  public setRoute(route: string): void {
    this.route = route;
  }

  public downloadSettings(
    clientId: number,
    withObjects = 'true',
  ): Observable<BackendResponseInterface<SettingsResponseInterface>> {
    const params = new HttpParams({
      fromObject: {
        client: clientId ? clientId.toString() : '',
        withObjects,
      },
    });
    return this.http.get<BackendResponseInterface<SettingsResponseInterface>>(
      `${environment.url}${this.route}`,
      {
        params,
      },
    );
  }
}
