import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ItemNetworkService } from '../../../../modules/tree-view/components/item/services/item.network';
import { Observable } from 'rxjs';
import { BackendResponseInterface } from '../../../../../../core/interfaces/backend.response.interface';
import { ProjectModel } from '../../../../modules/tree-view/models/project.model';
import { TreeListItemResponseInterface } from '../../../../../../shared/troi-tree-list/interfaces/tree-list-item-response.interface';
import { ProjectTypeEnum } from '../../../../modules/tree-view/enums/project-type.enum';
import { ProjectPropertiesInterface } from '../../../../modules/tree-view/interfaces/project-properties.interface';
import { FiltersService } from '../../../../services/filters.service';

@Injectable()
export class ProjectPlanService {
  public submitted = false;
  public pending = false;

  constructor(private itemNetwork: ItemNetworkService, private filtersService: FiltersService) {}

  public initForm(): FormGroup {
    return new FormGroup({
      startDate: new FormControl(Date.now(), Validators.required),
      endDate: new FormControl(moment().add(28, 'days').valueOf(), Validators.required),
      material: new FormControl(false),
      externalLabour: new FormControl(false),
      optionalPosition: new FormControl(false),
      takeDatesFromAssignments: new FormControl(false),
    });
  }

  save(
    data: ProjectModel,
    form: FormGroup,
  ): Observable<
    BackendResponseInterface<
      TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>
    >
  > {
    return this.itemNetwork.createProjectPlan(data, form, this.filtersService.actualFilters);
  }
}
