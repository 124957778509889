import { TroiDropdownListModel } from '../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { YearInterface } from '../interfaces/basic-settings.interface';
import { BaseSettingsModel } from './base-settings.model';
import { SettingsResponseInterface } from '../../../core/interfaces/settings/settings-response.interface';

export abstract class BaseBookingSettings<
  T extends SettingsResponseInterface
> extends BaseSettingsModel<T> {
  isVersionCorrect(settings, correctVersion: string): boolean {
    return settings.version && settings.version === correctVersion;
  }

  public buildBookingYears(years: YearInterface[]): Array<TroiDropdownListModel> {
    return years.map(
      (element: YearInterface): TroiDropdownListModel => ({
        label: element.year.toString(),
        value: element.year,
        active: true,
      }),
    );
  }
}
