import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  ApprovalHistoryResponseInterface,
  ApprovalResponseInterface,
} from '../interfaces/approvals-api.interfaces';
import { Routes } from '../enums/routes';

@Injectable()
export class ApprovalsNetwork {
  public constructor(public http: HttpClient) {}

  approvalPath(): string {
    return Routes.BOOKING_APPROVAL;
  }

  status(id: string): Observable<ApprovalResponseInterface> {
    return this.http.get<ApprovalResponseInterface>(
      `${environment.url}${this.approvalPath()}${id}`,
    );
  }

  history(id: string): Observable<ApprovalHistoryResponseInterface[]> {
    return this.http.get<ApprovalHistoryResponseInterface[]>(
      `${environment.url}${this.approvalPath()}${id}${Routes.HISTORY}`,
    );
  }

  start(id: string, payload: Record<string, unknown>[]): Observable<ApprovalResponseInterface> {
    return this.http.post<ApprovalResponseInterface>(
      `${environment.url}${this.approvalPath()}${id}${Routes.START}`,
      payload,
    );
  }

  direct(id: string): Observable<ApprovalResponseInterface> {
    return this.http.post<ApprovalResponseInterface>(
      `${environment.url}${this.approvalPath()}${id}${Routes.DIRECT}`,
      [],
    );
  }

  approve(id: string, payload: Record<string, unknown>): Observable<ApprovalResponseInterface> {
    return this.http.post<ApprovalResponseInterface>(
      `${environment.url}${this.approvalPath()}${id}${Routes.APPROVE}`,
      payload,
    );
  }

  decline(id: string, payload: Record<string, unknown>): Observable<ApprovalResponseInterface> {
    return this.http.post<ApprovalResponseInterface>(
      `${environment.url}${this.approvalPath()}${id}${Routes.DECLINE}`,
      payload,
    );
  }

  cancel(id: string, payload: Record<string, unknown>): Observable<ApprovalResponseInterface> {
    return this.http.post<ApprovalResponseInterface>(
      `${environment.url}${this.approvalPath()}${id}${Routes.CANCEL}`,
      payload,
    );
  }

  delegate(id: string, payload: Record<string, unknown>): Observable<ApprovalResponseInterface> {
    return this.http.post<ApprovalResponseInterface>(
      `${environment.url}${this.approvalPath()}${id}${Routes.DELEGATE}`,
      payload,
    );
  }

  edit(id: string, payload: Record<string, unknown>[]): Observable<ApprovalResponseInterface> {
    return this.http.post<ApprovalResponseInterface>(
      `${environment.url}${this.approvalPath()}${id}${Routes.EDIT}`,
      payload,
    );
  }
}
