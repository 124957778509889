import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { UserModel } from './user.model';
import { IUser } from './IUser';
import { StorageService } from '../storage/storage.service';
import { StorageKeys } from '../storage/storage.keys';

@Injectable()
export class UserService {
  public constructor(private storageService: StorageService) {}

  public saveUser(user: IUser): void {
    user.id = parseInt(user.id.toString());
    this.storageService.setItem(StorageKeys.USER, user);
  }

  public getUser(): UserModel | null {
    const userData = this.storageService.getItem(StorageKeys.USER);
    if (_.isNull(userData)) {
      return null;
    }

    const user: IUser = {
      id: userData.id,
      name: userData.name,
      surname: userData.surname,
      username: userData.username,
      avatarUrl: userData.avatarUrl,
      isLogged: userData.isLogged,
      lang: userData.lang,
    };
    return this.createNewUser(user);
  }

  public removeUser() {
    this.storageService.removeItem(StorageKeys.USER);
  }

  public setRememberMe(rememberMe: boolean) {
    this.storageService.setItem(StorageKeys.REMEMBER_ME, rememberMe);
  }

  public getRememberMe(): boolean {
    return this.storageService.getItem(StorageKeys.REMEMBER_ME);
  }

  public clearRememberMe() {
    this.storageService.removeItem(StorageKeys.REMEMBER_ME);
  }

  public setLoggedIn(value: boolean) {
    const userData = this.storageService.getItem(StorageKeys.USER);
    if (!_.isNull(userData)) {
      userData.isLogged = value;
      this.saveUser(userData);
    }
  }

  public getLoggedIn() {
    const userData = this.storageService.getItem(StorageKeys.USER);
    if (!_.isNull(userData)) {
      return userData.isLogged;
    }

    return false;
  }

  private createNewUser(userData: IUser) {
    return new UserModel(
      parseInt(userData.id.toString()),
      userData.name,
      userData.username,
      userData.avatarUrl,
      userData.isLogged,
      userData.lang,
    );
  }
}
