import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import {
  BackendResponseInterface,
  BackendResponseType,
} from '../../../../../core/interfaces/backend.response.interface';
import { FolderModel } from '../components/folder/models/folder.model';
import { TreeListItemResponseInterface } from '../../../../../shared/troi-tree-list/interfaces/tree-list-item-response.interface';
import { ProjectTypeEnum } from '../enums/project-type.enum';
import { ProjectPropertiesInterface } from '../interfaces/project-properties.interface';
import { mapValues } from 'lodash';
import { FiltersService } from '../../../services/filters.service';
import { FiltersParamsInterface } from '../../../../common/interfaces/filters-params.interface';
import { environment } from '../../../../../../environments/environment';
import { Routes } from '../../../enum/routes';
import { ModuleInterceptor } from '../../../../../core/enums/module-interceptor';
import { ProjectModel } from '../models/project.model';

@Injectable()
export class TreeViewListNetworkService {
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.PROJECT_LIST, '1');
  }

  public getTreeStructure(
    filtersParams: FiltersParamsInterface,
    lang: string,
  ): Observable<
    BackendResponseInterface<
      TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>[]
    >
  > {
    const params = new HttpParams({
      fromObject: {
        ...mapValues(filtersParams, FiltersService.stringifyFilterParam),
        lang,
      },
    });

    return this.http.get<
      BackendResponseInterface<
        TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>[]
      >
    >(`${environment.url}${Routes.TREE_LIST}`, {
      params,
      headers: this.headers,
    });
  }

  public moveToFolder(
    source: string,
    target: string,
    type: string,
  ): Observable<Record<string, string>> {
    return this.http.post<Record<string, string>>(
      `${environment.url}${Routes.TREE_LIST_MOVE_ITEM}`,
      {
        source,
        target,
        type,
        headers: this.headers,
      },
    );
  }

  public getChildrenTreeStructure(
    folder: FolderModel,
    filtersParams: FiltersParamsInterface,
  ): Observable<
    BackendResponseInterface<
      TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>[]
    >
  > {
    const params = new HttpParams({
      fromObject: {
        ...mapValues(filtersParams, FiltersService.stringifyFilterParam),
        parentFolder: folder.id,
        // projectFolder: folder.isCustomer() ? '' : folder.id,
      },
    });

    return this.http.get<
      BackendResponseInterface<
        TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>[]
      >
    >(`${environment.url}${Routes.TREE_LIST_CHILDREN_LAZY}`, {
      params,
      headers: this.headers,
    });
  }
}
