import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BookingSettingsService } from '../services/booking-settings.service';
import { ModuleInterceptor } from '../../../../core/enums/module-interceptor';
import { StorageNotificationService } from '../../../../core/notifications/storageNotification.service';
import { BackendResponseInterface } from '../../../../core/interfaces/backend.response.interface';
import { BookingSettingsInterface } from '../interfaces/booking-settings.interface';
import { Routes } from '../../../../core/enums/routes';

@Injectable()
export class BookingSettingsInterceptor implements HttpInterceptor {
  blocked = false;

  constructor(
    public settingService: BookingSettingsService,
    public translateService: TranslateService,
    public notificationService: StorageNotificationService,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.overrideRequest(req, next);
  }

  private overrideRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && req.headers.get(ModuleInterceptor.BOOKINGS)) {
          const headerLastModified = parseInt(event.headers.get('settings-last-modified'));
          const headerDataLastModified = parseInt(event.headers.get('data-last-modified'));
          let settings = this.settingService.settings
            ? this.settingService.settings
            : this.settingService.getSettingsFromLS();
          if (settings && !this.blocked) {
            if (!settings.lastDownload) {
              settings.lastDownload = headerLastModified;
              this.settingService.saveSettingsToLS(settings);
            }
            if (!settings.dataLastDownload) {
              settings.dataLastDownload = headerDataLastModified;
              this.settingService.saveSettingsToLS(settings);
            }
            if (settings.lastDownload && headerLastModified !== settings.lastDownload) {
              if (
                settings.dataLastDownload &&
                headerDataLastModified !== settings.dataLastDownload
              ) {
                settings.lastDownload = headerLastModified;
                settings.dataLastDownload = headerDataLastModified;
                this.settingService.saveSettingsToLS(settings);
              } else {
                this.translateService
                  .get('PriceList.error.settingsRefresh')
                  .subscribe((message) => {
                    this.notificationService.showInfo(message);
                  });
                this.blocked = true;
                const actualFromLS = this.settingService.getSettingsFromLS();
                if (actualFromLS && settings.client !== actualFromLS.client) {
                  settings = actualFromLS;
                }
                this.settingService.basicSettingsNetwork.setRoute(Routes.ACCOUNTING_SETTINGS);
                this.settingService.basicSettingsNetwork
                  .downloadSettings(settings.client, 'false')
                  .subscribe((result: BackendResponseInterface<BookingSettingsInterface>) => {
                    this.settingService.settings = result.data;
                    this.settingService.settings.lastDownload = headerLastModified;
                    this.settingService.settings.dataLastDownload = headerDataLastModified;
                    this.settingService.saveSettingsToLS(this.settingService.settings);
                    this.blocked = false;
                    window.location.reload();
                  });
              }
            }
          }
          return event;
        }
        return event;
      }),
    );
  }
}

export const BookingSettingsInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: BookingSettingsInterceptor,
  multi: true,
};
